import { Directive, ElementRef } from '@angular/core';
import {
  CdkHeaderCellDef,
  CdkHeaderCell,
  CdkColumnDef,
} from '@angular/cdk/table';

@Directive({
  selector: '[tableHeaderCell]',
  providers: [{ provide: CdkHeaderCellDef, useExisting: TableHeaderCellDef }],
})
export class TableHeaderCellDef extends CdkHeaderCellDef {}

@Directive({
  selector: 'table-header-cell',
})
export class TableHeaderCellDirective extends CdkHeaderCell {
  constructor(columnDef: CdkColumnDef, elementRef: ElementRef) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.add(
      `table-column-${columnDef.cssClassFriendlyName}`
    );
  }
}
