<div class="toast-container">
  <div class="toast-cell">
    <ng-container *ngFor="let alert of alertMessage; let i = index">
      <div class="toast add-margin" [class.toast--green]="alert.class === 'success'"
        [class.toast--blue]="alert.class === 'info'" [class.toast--red]="alert.class === 'error'" [class.toast--yellow]="alert.class === 'warn'">
        <div class="toast-icon">
          <i class="fa" [class.fa-check]="alert.class === 'success'" [class.fa-exclamation]="alert.class === 'warn'" [class.fa-info]="alert.class === 'info'"
            [class.fa-times]="alert.class === 'error'"></i>
        </div>
        <div class="toast-content" [ngSwitch]="alert.class">
          <p class="toast-type" *ngSwitchCase="'info'">
            Info
          </p>
          <p class="toast-type" *ngSwitchCase="'error'">
            Error
          </p>
          <p class="toast-type" *ngSwitchCase="'warn'">
            Warning
          </p>
          <p class="toast-type" *ngSwitchDefault>
            Success
          </p>
          <p class="toast-message">{{alert.message}}</p>
        </div>
        <div class="toast-close" (click)="close(i)">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642"
            xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 15.642 15.642">
            <path fill-rule="evenodd"
              d="M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061  c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061  l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541  l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z">
            </path>
          </svg>
        </div>
      </div>
    </ng-container>
  </div>
</div>
