import baseConfiguration from './base.configuration';
import myConfiguration from './my.configuration';
import sgConfiguration from './sg.configuration';
import idConfiguration from './id.configuration';
import ziConfiguration from './zi.configuration';
import thConfiguration from './th.configuration';
import zvConfiguration from './zv.configuration';
import vuConfiguration from './vu.configuration';
import zuConfiguration from './zu.configuration';

const DEFAULT_COUNTRY_CODE = 'sg';
const TESTING_APP_URL_KEY = ['preview', 'localhost'];
const LOCAL_DEVELOPMENT_URL_KEY = ['localhost'];
const configurations = {
  'my': myConfiguration,
  'sg': sgConfiguration,
  'id': idConfiguration,
  'th': thConfiguration,
  'vu': vuConfiguration,
  'zv': zvConfiguration,
  'zi': ziConfiguration,
  'zu': zuConfiguration
};

export function isTestingEnvironment(): boolean {
  return TESTING_APP_URL_KEY.some(v => window.location.origin.includes(v));
}

export function isLocalDevelopment(): boolean {
  return LOCAL_DEVELOPMENT_URL_KEY.some(v => window.location.origin.includes(v));
}

let selectedCountry = localStorage.getItem('countryId') || DEFAULT_COUNTRY_CODE;

export default Object.freeze(Object.assign({}, baseConfiguration, configurations[selectedCountry.toLowerCase()]));
