
/**
 * Remove trailing slash.
 */
function trimSlash(url: string): string {
  let cleanScreen = url.trim();
  const lastCharacterPosition = cleanScreen.length - 1;
  if (cleanScreen[lastCharacterPosition] === '/') {
    cleanScreen = cleanScreen.substring(0, lastCharacterPosition);
  }
  return cleanScreen;
}

/**
 * Should match url:
 * /path/to/route
 * /path/to/route/
 */
function matchScreens(screen: string, screens: string[]): boolean {
  return screens.includes(trimSlash(screen));
}

export {
  trimSlash,
  matchScreens
}
