import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import Config from '../../configuration/configuration';

@Pipe({
    name: 'dateTimeFormat'
  })
  export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
      return value ?
            super.transform(value, Config.date_format.date_time, '', Config.date_format.locale) : '';
    }
  }
