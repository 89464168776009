import { Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthenticationService } from './service';
import { environment } from '@shared-environments/environment';
import { VERSION } from '@angular/core';

function cleanUrl(url: string): string {
  const nextUrl = new URL(url);
  nextUrl.hash = '';
  // Set by Kong while redirecting to cache bust the index file
  nextUrl.searchParams.delete('t');
  return nextUrl.toString();
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private authentication: AuthenticationService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = this.authentication.getAuthorizationToken();
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`)
        .set('Angular-Version', VERSION.full),
    });

    return next.handle(authReq).pipe(
      tap(
        (event) => { },
        (error) => {
          if (error instanceof HttpErrorResponse) {
            if ((error && error.status === 401 && (error.error && error.error.code && error.error.code === 40101))) {
              return;   //error.error.code !== 40101 -> To handle api that return 40101 for unauthorized access but don't need to log out
            } else if (error && error.status === 401 && error.error && error.error.error === 'invalid_token') {
              this.authentication.clearAuthorizationToken();
              window.location.href = environment.Login_URI + encodeURIComponent(cleanUrl(window.location.href)) + '&v2=1';
            }
          }
        }
      )
    );
  }
}

export const authInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthenticationInterceptor,
  multi: true,
};
