
import { Injectable } from '@angular/core';

@Injectable()
export class MicroLoanDetailSharedVariable {
  pic: string;
  memberTypeId: string;
  borrowerDetail: any;
  loanDetail: any;
  banks: Array<any>;
  countryRegions: Array<any>;
  currencies: Array<any>;
  employmentStatuses: Array<any>;
  accreditedStatuses: Array<any>;
  countryCode: string;
  productId: string;
  assignmentList: any;
  loanAssignment: any;
  accessibleTab: Array<string> = [];
  queryParam: string;
  constructor() {
    this.queryParam = '';
    this.banks = [];
    this.countryRegions = [];
    this.currencies = [];
    this.employmentStatuses = [];
    this.accreditedStatuses = [];
    this.borrowerDetail = {
      applicantPhoneNumber: '',
      applicantGender: '',
      applicantMobilePhoneNumber: '',
      applicantNric: '',
      ApplicantMonthlySalary: '',
      ApplicantlastTwoYearsIncome: '',
      maritalStatusId: '',
      id: '',
      fullName: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      mobilePhoneNumber: '',
      monthlyRevenue: '',
      ecommercePlatform: '',
      isSellingOnline: '',
      birthDate: '',
      homeAddress: '',
      spouseFullName: '',
      spouseDateOfBirth: '',
      spouseIcNumber: '',
      spouseMobilePhoneNumber: '',
      companyName: '',
      companyPhoneNumber: '',
      companyAnnualRevenue: '',
      companyUen: '',
      companyBirthDate: '',
      companyEntityType: '',
      companyIndustry: '',
      businessAddress: '',
      businessOwnership: '',
      businessIndustry: '',
      businessEstablishlength: '',
      businessAddressOwnership: '',
      businessLengthOfStay: '',
      homeAddressOwnership: '',
      homeLengthOfStay: '',
      address1: '',
      address2: '',
      countryCode: '',
      companyAddress1: '',
      companyAddress2: '',
      referralRemark: '',
      referralCode: '',
      leadSource: '',
      virtualAccountNumber: '',
      agreeToBeVisited: '',
      balance: '',
      memberHomeAddress: '',
      memberCompanyAddress: '',
      uuid: '',
    };
    this.assignmentList = {
      sales: new Array<any>(),
      phoneVerification: new Array<any>(),
      verificationOrder: new Array<any>(),
      screening: new Array<any>(),
      credit: new Array<any>(),
      underwriter: new Array<any>(),
      collection: new Array<any>()
    };
    this.loanDetail = {
      id: '',
      statusId: '',
      amount: '',
      interestRate: '',
      tenor: '',
      borrowerName: '',
      borrowerId: '',
      userName: '',
      companyName: '',
      guaranteeFee: '',
      appliedAmount: '',
      appliedTenor: '',
      purpose: '',
      loanCode: '',
      createdAt: '',
      rejectionReasonId: '',
      partnerId: '',
      serviceFee: '',
      originationFee: '',
      tenorType: '',
      interestType: '',
      installmentType: '',
      gst: '',
      retentionSum: '',
      ddaRequirement: '',
      preDisbursement: '',
      preDisbursementText1: '',
      preDisbursementText2: '',
      preDisbursementText3: '',
      blockMicroloanPreofferBorrowerNotifications: '',
      stageId: '',
      uuid: '',
      grade: '',
    };
  }
}
