import {
  Component, OnInit, AfterContentInit,
  Input, Output, EventEmitter,
  Directive, ContentChildren, QueryList,
  forwardRef,
  ElementRef, ViewChild, ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Query } from '@shared-models/query.class';
import * as _ from 'lodash';
export const ComponentValueAccessor: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TableComponent),
    multi: true
};
/* tslint:disable-next-line:directive-selector */
@Directive({selector: 'action'})
export class ButtonDirective {
    @Input() class: string;
    @Input() content: string;
    @Input() title: string;
    @Input() condition: any[];
    @Output() onClick: EventEmitter<any> = new EventEmitter();
}

/* tslint:disable-next-line:directive-selector */
@Directive({selector: 'column'})
export class ColumnDirective implements AfterContentInit {
    @Input() column: string;
    @Input() fallbackColumn: string;
    @Input() header: string;
    @Input() value: string[];
    @Input() pipe: string;
    @Input() sort = true;
    @Input() show: boolean = true;
    @Input() styles: any = {};
    @Input() class: string;
    @Output() onClick: EventEmitter<any> = new EventEmitter();
    @ContentChildren(ButtonDirective) childrenButtons: QueryList<ButtonDirective>;
    buttons = [];
    ngAfterContentInit() {
        if (this.childrenButtons && this.childrenButtons.length) {
            this.buttons = this.childrenButtons.toArray();
        }
    }
}

@Component({
    selector: 'app-table',
    templateUrl: './table.deprecated.component.html',
    styleUrls: ['./table.deprecated.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ ComponentValueAccessor ]
})
/**
 * @deprecated
 *
 * Use TableNewComponent instead
 */
export class TableComponent implements OnInit, AfterContentInit, ControlValueAccessor {
    @Input('data') data: any[];
    @Input('total') total: number;
    @Input('size') size: number;
    @Input('orderBy') orderBy: string;
    @Input('orderAsc') orderAsc: boolean;
    @Input('hasCheckBox') hasCheckBox: boolean;
    @Input('checkBoxCondition') checkBoxCondition: any[];
    @Input('class') customClass: string;
    @Output() onSort: EventEmitter<any> = new EventEmitter(); ;
    @Output() onPageChange: EventEmitter<any> = new EventEmitter(); ;
    @Output() onSizeChange: EventEmitter<any> = new EventEmitter(); ;
    @Output() onClick: EventEmitter<any> = new EventEmitter();
    @ContentChildren(ColumnDirective) childrenColumns: QueryList<ColumnDirective>;

    query: Query;
    sortColumn;
    columns = [];
    selectAll = false;
    constructor() {}
    ngOnInit() {
        this.query = {
            keyword: '',
            page: 1,
            recordsPerPage: this.size || 10,
            totalRecords: this.total || this.data ? this.data.length : 0,
            orderColumn: this.orderBy || 'id',
            orderAscending: this.orderAsc !== null ? this.orderAsc : true,
        };
    }
    ngAfterContentInit() {
        if (this.childrenColumns && this.childrenColumns.length) {
            this.columns = this.childrenColumns.toArray();
        } else {
            this.columns = Object.keys(Object.assign({}, ...this.data)).map(m =>({
                column: m,
                header: m,
                number: false,
                date: false,
                sort: true,
            }));
        }
        this.sortColumn = this.columns
            .filter(f => f.sort)
            .map(m => m.column)
            .reduce((object, key) => ({
            ...object,
            [key]: {
                active: this.orderBy ? this.orderBy === key ? true : false : false,
                asc: this.orderAsc === undefined ? true : this.orderAsc,
            }
        }), {});
    }
    // Table functions helper
    getRowValue(data: any, column: ColumnDirective) {
        if (!column) { return ''; }
        if (!column.value
            || !Array.isArray(column.value)
            || !column.value.length ) {
            if(column.fallbackColumn && !_.has(data, column.column)) {
                return _.get(data,column.fallbackColumn)
            }
            return  _.get(data, column.column, '');
        }
        let result = '';
        column.value.forEach(value => result += `${_.get(data, value, '')} `);
        return result.trim();
    }
    invokeOnClick(data: any) {
        if (this.onClick.observers.length > 0) {
            this.onClick.emit(data);
        }
    }
    invokeOnColumnClick(column: ColumnDirective, data: any) {
        if (column.onClick.observers.length > 0) {
            column.onClick.emit(data);
        }
    }
    invokeSort(sortKey: string) {
        if (this.showSort()) {
            Object.keys(this.sortColumn).forEach((key) => {
                this.sortColumn[key].active = false;
                if (key !== sortKey) {
                  this.sortColumn[key].asc = true;
                }
            });
            this.sortColumn[sortKey].active = true;
            this.sortColumn[sortKey].asc = !this.sortColumn[sortKey].asc;
            this.query.orderColumn = sortKey;
            this.query.orderAscending = this.sortColumn[sortKey].asc;
            this.onSort.emit(sortKey);
            this.invokeOnChange();
        }
    }
    invokePageChange(page: number) {
        if (this.showPaging()) {
            this.query.page = page;
            this.onPageChange.emit(page);
        }
    }
    invokeSizeChange(size: number) {
        if (this.showSize()) {
            this.query.page = 1;
            this.query.recordsPerPage = size;
            this.onSizeChange.emit(size);
        }
    }
    buttonClick(button, row) {
        if (button
            && button.onClick
            && button.onClick.observers.length > 0) {
                button.onClick.emit(row);
            }
    }
    customCondition(condition, row) {
        if (!condition || condition.length === 0) { return true; }
        // example of value ['proof', '>', 0]
        return this.compare(
            row[condition[0]],
            condition[1],
            condition[2],
        );
    }
    // Value Accessor Interface
    get value(): Query { return this.query; };
    set value(v: Query) {
        if (v !== this.query) {
            this.query = v;
            this.onChange(v);
        }
    }
    writeValue(value: Query) {
        if (value && this.query) {
            this.onChange(this.query);
        }
    }
    invokeOnChange() {
        this.onChange(this.query);
    }
    onChange = (_) => {};
    onTouched = () => {};
    registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
    registerOnTouched(fn: () => void): void { this.onTouched = fn; }
    // Helper
    showSort() {
        return this.onSort.observers.length > 0;
    }
    showPaging() {
        return this.onPageChange.observers.length > 0;
    }
    showSize() {
        return this.onSizeChange.observers.length > 0;
    }
    compare(post, operator, value) {
        switch (operator) {
          case '>':   return post > value;
          case '<':   return post < value;
          case '>=':  return post >= value;
          case '<=':  return post <= value;
          case '==':  return post == value;
          case '!=':  return post != value;
          case '===': return post === value;
          case '!==': return post !== value;
        }
    }
    onToggleSelectAll() {
        this.selectAll = !this.selectAll;
        if (this.checkBoxCondition.length) {
            this.data.filter(row => this.customCondition(this.checkBoxCondition, row)).forEach(d => d['selected'] = this.selectAll);
        } else {
            this.data.forEach(d => d['selected'] = this.selectAll);
        }
    }
    onCheckBoxClick(row: any) {
        row['selected'] = !row['selected'];
    }
    getSelectedData() {
        return this.data.filter(f => f['selected'] === true);
    }

}
