import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  IterableDiffers,
  ChangeDetectorRef,
  ElementRef,
  Attribute,
} from '@angular/core';
import { CDK_TABLE_TEMPLATE, CdkTable } from '@angular/cdk/table';
@Component({
  selector: 'app-table-new',
  template: CDK_TABLE_TEMPLATE,
  styleUrls: ['table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// export class TableNewComponent<T> extends CdkTable<T> {
//   constructor(
//     _differs: IterableDiffers,
//     _changeDetectorRef: ChangeDetectorRef,
//     elementRef: ElementRef,
//     @Attribute('role') role: string,
//   ) {
//     super(_differs, _changeDetectorRef, elementRef, role);
//   }
// }
export class TableNewComponent<T>  {
  constructor(
    _differs: IterableDiffers,
    _changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    @Attribute('role') role: string
  ) {
  }
}
