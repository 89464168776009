import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit {
  @Input() data: string;
  @Input() name: string;
  @Input() checked = false;
  @Input() label = '';
  @Output() change = new EventEmitter();

  @Input()
  @HostBinding('class.inline')
  inline: boolean = false;

  id = `${new Date().getTime()}_${Math.random()}`;

  constructor() {}

  ngOnInit() {}

  onChange(event) {
    const { target } = event;
    if (target.checked) {
      this.change.emit(target.value);
    }
  }
}
