import { Component, EventEmitter, Input, OnChanges, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Modal } from '@shared-components/modal';
import { DocService } from 'app/modules/facility_v2/services/doc.service';

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocViewerComponent implements OnChanges {
  @Input('visible') visible = false;
  @Input('docId') docId: number = null;
  @Input('forceDownload') forceDownload = true;
  @Input('fileName') fileName: string = null;
  @Input('isModal') isModal = true;

  @Output('close') close = new EventEmitter();
  @Output('error') error = new EventEmitter();

  @ViewChild('pdfModal', { static: false }) pdfModal: Modal;

  getDocumentType(fileName: string): string {
    return fileName.toLowerCase().split('.').pop().toLowerCase();
  }

  constructor(private readonly docService: DocService) {
  }

  ngOnChanges(changes) {
    const { visible, docId } = changes;

    if (
      !this.isModal
      && (docId && (docId.currentValue !== docId.previousValue))
      || (visible && visible.currentValue)
    ) {
      this.viewDoc();
    }
  }

  handleClose() {
    this.close.emit();
  }

  handleError(error) {
    this.error.emit(error);
  }

  handleCloseClick() {
    this.pdfModal.close();
  }

  getViewableFileType(docType) {
    if ( !docType ) return false;
    const viewableFileExtensions = ['jpg', 'jpeg', 'png', 'gif', 'pdf'];
    return viewableFileExtensions.some(function(el){ return el === docType});
  }

  previewPdf(): void {
    this.docService.createBlobDocById(this.docId.toString()).subscribe((data) => {
      const url = window.URL.createObjectURL(data);
      if (this.isModal) {
        this.pdfModal.open();
      }
      setTimeout(() => {
        const docIFrame = (document.querySelector('.content-preview #ifDoc') as HTMLIFrameElement);
        if (docIFrame) {
          docIFrame.src = url;
        }
      }, 1000);
    });
  }

  viewDoc(): void {
    if(!this.getViewableFileType(this.getDocumentType(this.fileName))){
      const docIFrame = (document.querySelector('.content-preview #ifDoc') as HTMLIFrameElement);
      if (docIFrame) {
        docIFrame.src = '';
      }
      return;
    }
    switch (this.getDocumentType(this.fileName)) {
      case 'pdf':
        this.previewPdf();
        break;
      case 'gif':
      case 'jpg':
      case 'jpeg':
      case 'png':
        this.previewPdf();
        break;
      default:
        if (this.forceDownload) {
          this.docService.downloadDocById(this.docId)
            .subscribe(
              res => {
                this.docService.createFile(res, this.fileName, res.type);
                this.handleClose();
              },
              err => this.handleError(err)
            );
        }
        break;
    }
  }
}
