import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  isModalkuGroup: boolean;
  modalkuCountryList: any;
  ENVIRONMENT: any;

  constructor(private profileService: ProfileService) {
    this.ENVIRONMENT = environment;
    this.modalkuCountryList = this.profileService.getModalkuCountryList();
  }

  getFullYear() {
    const date = new Date();
    return date.getFullYear();
  }

  getFooterNote() {
    if (this.modalkuCountryList.indexOf(this.profileService.getCountryId())) {
      return 'Funding Asia Group';
    } else {
      return 'Modalku / PT Mitrausaha Indonesia Grup';
    }
  }

}
