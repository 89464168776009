import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

// Modules
import { ControlsModule } from './shared/controls/controls.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from 'primeng/dropdown';
import { LottieModule } from 'ngx-lottie';
import { ComponentsModule } from './shared/components/components.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { PermissionModule } from '@shared-services/permission';

// COMPONENTS
import { AppComponent } from './app.component';
import { HelperComponent } from './spread/helper.component';
import { SharedComponent } from './spread/shared.component';
import { NotFoundPageComponent } from './shared/components/not-found-page/not-found-page.component';

import { routing } from './app.routing';
import {
  authInterceptorProvider,
  LOGIN_URL,
  AuthenticationService,
} from '@app/common/authentication';

import player from 'lottie-web';

// SERVICES
import { LoadingService } from '@shared/controls/services/loading.service';
import { AlertService } from '@shared/shared/commons/alert.service';
import { FeatureFlagService } from '@shared-services/feature-flag.service';
import { ReviewAppService } from '@shared-services/review-app.service';
import { DynamicProductionEnvironmentService } from '@shared-services/dynamic-production-environment.service';
import { MessageService } from '@shared-services/message.service';

// Language
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

// Interceptor
import { AppInterceptor } from '@shared/interceptor/app-interceptor';
import { WebpackTranslateLoader } from 'app/interceptors/translate-loader';
import { environment } from '@shared-environments/environment';
import { httpEncoderInterceptorProvider, loggingInterceptorProvider } from '@app/common/http';

export function playerFactory() {
  return player;
}
declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

@NgModule({
  declarations: [AppComponent, SharedComponent, NotFoundPageComponent],
  imports: [
    HttpClientModule,
    DropdownModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    HelperComponent,
    NgbModule,
    NoopAnimationsModule,
    PipesModule,
    ComponentsModule,
    ControlsModule,
    LottieModule.forRoot({ player: playerFactory }),
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    PermissionModule.forRoot(),
  ],
  providers: [
    AuthenticationService,
    { provide: LOGIN_URL, useValue: `${environment.api}/login` },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    authInterceptorProvider,
    loggingInterceptorProvider,
    httpEncoderInterceptorProvider,
    LoadingService,
    DecimalPipe,
    AlertService,
    MessageService,
    FeatureFlagService,
    ReviewAppService,
    DynamicProductionEnvironmentService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
