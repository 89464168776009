import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  ViewEncapsulation,
} from '@angular/core';
import {
  CDK_ROW_TEMPLATE,
  CdkHeaderRow,
  CdkHeaderRowDef,
} from '@angular/cdk/table';

@Directive({
  selector: '[tableHeaderRow]',
  providers: [
    { provide: CdkHeaderRowDef, useExisting: TableHeaderRowDirective },
  ],
  inputs: ['columns: tableHeaderRow'],
})
export class TableHeaderRowDirective extends CdkHeaderRowDef {}

@Component({
  selector: 'table-header-row',
  template: CDK_ROW_TEMPLATE,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TableHeaderRowComponent extends CdkHeaderRow {}
