import { NgModule } from "@angular/core";
import { ModalModule } from "../modal";
import { ConfirmationModalComponent } from "./confirmation-modal.component";

@NgModule({
    imports:[
        ModalModule
    ],
    declarations:[
        ConfirmationModalComponent
    ],
    exports:[
        ConfirmationModalComponent
    ]
})

export class ConfirmationModalModule {

}