import { guid } from '@datorama/akita';

export interface ErrorLog {
  id: string;
  message: string;
  stack: string;
  url: string;
}

export function createErrorLog(params: Partial<ErrorLog>) {
  return {
    id: guid(),
    ...params,
  } as ErrorLog;
}
