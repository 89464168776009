import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css'],
})
export class MaintenanceComponent implements OnInit {
  isIndonesiaHost: boolean = false;

  constructor(
    private utilityService: UtilityService
  ) {
  }

  ngOnInit() {
    this.isIndonesiaHost = this.utilityService.isIndonesiaHost();
  }

}
