
import { throwError as observableThrowError, Observable, catchError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  constructor(private http: HttpClient) {
  }

  private _errorHandler(error: any): Observable<any> {
    let json;
    try {
      json = error;
    } catch (e) {
      return observableThrowError(error);
    }

    return observableThrowError(json);
  }

  logout() {
    return this.http.get(environment.Logout_URI).pipe(
      catchError(error => this._errorHandler(error)));
  }
}
