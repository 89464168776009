import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  ViewEncapsulation,
} from '@angular/core';
import { CDK_ROW_TEMPLATE, CdkRow, CdkRowDef } from '@angular/cdk/table';

@Directive({
  selector: '[tableRow]',
  providers: [{ provide: CdkRowDef, useExisting: TableRowDirective }],
  inputs: ['columns: tableRowColumns', 'when: tableRowWhen'],
})
export class TableRowDirective<T> extends CdkRowDef<T> {}

@Component({
  selector: 'table-row',
  template: CDK_ROW_TEMPLATE,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TableRowComponent extends CdkRow {}
