import { Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpParams, HttpResponse, HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError, finalize, tap } from 'rxjs';
import { HttpUrlEncodingCodec } from './encoder';
import { getMessage } from '@app/common/error-handler/utils';
import { ErrorLogger } from '@app/common/error-handler/logger';
import { LoadingService } from '@shared/controls/services/loading.service';

@Injectable({
  providedIn: 'root',
})
export class HttpEncoderInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const newReq = req.clone({
      params: new HttpParams({
        encoder: new HttpUrlEncodingCodec(),
        fromString: req.params.toString(),
      }),
    });
    return next.handle(newReq);
  }
}

export const httpEncoderInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpEncoderInterceptor,
  multi: true,
};

@Injectable({
  providedIn: 'root',
})
export class LoggingInterceptor implements HttpInterceptor {
  constructor(protected errorService: ErrorLogger) {
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const startTime = Date.now();
    let status: string;
    let response: string;

    return next.handle(req).pipe(
      tap(
        event => {
          status = '';
          if (event instanceof HttpResponse) {
            status = 'succeeded';
            response = `\nResponse:\n\tStatus:${event.status} ${event.statusText}\n\tBody:${event.body && JSON.stringify(event.body)}`;
          }
        },
        (err) => {
          status = 'failed';
          if (err instanceof HttpErrorResponse) {
            const { error } = err;
            response = `\nResponse:\n\tError: ${getMessage(error)}`;
          }
        }
      ),
      finalize(() => {
        const elapsedTime = Date.now() - startTime;
        const message = `${req.method} ${req.urlWithParams} ${status} in ${elapsedTime} ms`;
        const requestBody = req.body && `\n\tRequest Body: ${JSON.stringify(req.body)}`;
        this.logDetails(message, requestBody + response, status !== 'succeeded');
      })
    );
  }
  private logDetails(message: string, stack: string, isError: boolean) {
    // TODO save to http log
  }
}

export const loggingInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoggingInterceptor,
  multi: true,
};

@Injectable({
  providedIn: 'root',
})
export class LoadingInterceptor implements HttpInterceptor {
  constructor(protected loadingService: LoadingService) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loadingService.addProcess();
    return next
      .handle(request)
      .pipe(
        catchError((err) => {
          this.loadingService.removeProcess();
          return throwError(err);
        })
      )
      .pipe(
        tap((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            // this.loadingService.removeProcess();
          }
          return evt;
        }),
        finalize(() => {
          this.loadingService.removeProcess();
        })
      );
  }
}

export const loadingInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoadingInterceptor,
  multi: true,
};
