import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TableNewComponent } from './table.component';
import { TableColumnDirective } from './table-column.directive';
import {
  TableHeaderCellDef,
  TableHeaderCellDirective,
} from './table-header-cell.component';
import { TableCellDef, TableCellDirective } from './table-cell.component';
import {
  TableHeaderRowComponent,
  TableHeaderRowDirective,
} from './table-header-row.component';
import { TableRowComponent, TableRowDirective } from './table-row.component';
import {
  TableComponent,
  ColumnDirective,
  ButtonDirective,
} from './table.deprecated.component';
import {
  DynamicPipeModule
} from '@shared/pipes/dynamic-pipe/dynamic-pipe.module'

const resources = [
  TableNewComponent,
  TableColumnDirective,
  TableHeaderCellDef,
  TableHeaderCellDirective,
  TableCellDef,
  TableCellDirective,
  TableHeaderRowComponent,
  TableHeaderRowDirective,
  TableRowComponent,
  TableRowDirective,
  TableComponent,
  ColumnDirective,
  ButtonDirective,
];

@NgModule({
  imports: [CommonModule, FormsModule, PipesModule, NgxPaginationModule,DynamicPipeModule],
  exports: resources,
  declarations: resources,
})
export class TableModule {}
