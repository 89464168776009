import { Component, OnInit } from '@angular/core';
import { AlertService, AlertMessage } from '../../shared/commons/alert.service';

@Component({
  selector: 'app-toast-messages',
  templateUrl: './toast-messages.component.html',
  styleUrls: ['./toast-messages.component.css'],
})

export class ToastMessagesComponent implements OnInit {
    alertMessage = [];
    class = "toast--green"
    constructor(private alertService: AlertService) {}
    ngOnInit() {
        this.alertService.alertStatus.subscribe((alert: AlertMessage) => {
            if (alert && alert.message && alert.message.trim() !== '') {
                this.alertMessage.push({
                    message: alert.message,
                    class: alert.severity,
                });
                setTimeout(() => {
                    this.alertMessage.shift();
                }, alert.duration || 3500);
            }
        });
    }
    close(index) {
        this.alertMessage.splice(index, 1);
    }
}

