import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { getMessage } from '@app/common/error-handler/utils';
import { ErrorService } from '@app/state/error/error.service';
@Injectable({
  providedIn: 'root'
})
export class ErrorLogger implements ErrorHandler {
  constructor(private injector: Injector, private service: ErrorService) { }

  handleError(err: any) {
    if (err instanceof HttpErrorResponse) {
      const { error } = err;
      console.error('ERROR', err);
      this.service.addLog({
        message: 'HttpErrorResponse',
        stack: `\nResponse:\n\tError: ${getMessage(error)}`,
        url: window.location.href
      });
    } else if (err instanceof Error) {
      // hack since fix its in later version of ngx-lottie
      if (err.stack.includes('Lottie')) { return; }
      console.error('ERROR', err);
      this.service.addLog({
        message: err.message,
        stack: err.stack,
        url: window.location.href
      });
    }
  }
}
