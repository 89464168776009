import { of as observableOf, throwError as observableThrowError, Observable, take, refCount, mergeMap, map, catchError, publishLast, retryWhen } from 'rxjs';
import { ADDRESS_FACILITY_DOC_TYPES, ADDRESS_DMS, ADDRESS_FACILITY_DOC } from '@shared/shared/commons/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from '@shared-services/service-base.service';
import { LoadingService } from '@shared/controls/services/loading.service';

@Injectable()
export class DocService extends ServiceBase {
  mimeTypes: Array<any>;
  readonly invoiceDocType = 'INVOICE';
  readonly drawdownDocType = 'DF_BIDDING_CONFIRMATION';
  readonly carPhotosDocType: string = 'DF_CAR_PHOTO';
  readonly inspectionReportsDocType: string = 'DF_VEHICLE_REPORT';
  readonly vehicleOwnershipCertificationDocType: string = 'DF_RC';
  readonly paymentProofDocType: string = 'DF_PAYMENT_PROOF';
  readonly dfOthersDocType: string = 'DF_OTHERS';
  readonly eautoDocType: string = 'DF_EAUTO';
  readonly dfSettlementReceipt: string = 'DF_UCD_SETTLEMENT_RECEIPT';
  constructor(
    http: HttpClient,
    private loadingService: LoadingService
  ) {
    super(http);
    this.mimeTypes = new Array<any>();
    this.setMimeType('.ez', 'application/andrew-inset');
    this.setMimeType('.aw', 'application/applixware');
    this.setMimeType('.atom', 'application/atom+xml');
    this.setMimeType('.atomcat', 'application/atomcat+xml');
    this.setMimeType('.atomsvc', 'application/atomsvc+xml');
    this.setMimeType('.ccxml', 'application/ccxml+xml');
    this.setMimeType('.cu', 'application/cu-seeme');
    this.setMimeType('.davmount', 'application/davmount+xml');
    this.setMimeType('.ecma', 'application/ecmascript');
    this.setMimeType('.emma', 'application/emma+xml');
    this.setMimeType('.epub', 'application/epub+zip');
    this.setMimeType('.pfr', 'application/font-tdpfr');
    this.setMimeType('.stk', 'application/hyperstudio');
    this.setMimeType('.jar', 'application/java-archive');
    this.setMimeType('.ser', 'application/java-serialized-object');
    this.setMimeType('.class', 'application/java-vm');
    this.setMimeType('.js', 'application/javascript');
    this.setMimeType('.json', 'application/json');
    this.setMimeType('.lostxml', 'application/lost+xml');
    this.setMimeType('.hqx', 'application/mac-binhex40');
    this.setMimeType('.cpt', 'application/mac-compactpro');
    this.setMimeType('.mrc', 'application/marc');
    this.setMimeType('.ma,.nb,.mb', 'application/mathematica');
    this.setMimeType('.mathml', 'application/mathml+xml');
    this.setMimeType('.mbox', 'application/mbox');
    this.setMimeType('.mscml', 'application/mediaservercontrol+xml');
    this.setMimeType('.mp4s', 'application/mp4');
    this.setMimeType('.doc,.dot', 'application/msword');
    this.setMimeType('.mxf', 'application/mxf');
    this.setMimeType('.oda', 'application/oda');
    this.setMimeType('.opf', 'application/oebps-package+xml');
    this.setMimeType('.ogx', 'application/ogg');
    this.setMimeType('.onetoc,.onetoc2,.onetmp,.onepkg', 'application/onenote');
    this.setMimeType('.xer', 'application/patch-ops-error+xml');
    this.setMimeType('.pdf', 'application/pdf');
    this.setMimeType('.pgp', 'application/pgp-encrypted');
    this.setMimeType('.asc,.sig', 'application/pgp-signature');
    this.setMimeType('.prf', 'application/pics-rules');
    this.setMimeType('.p10', 'application/pkcs10');
    this.setMimeType('.p7m,.p7c', 'application/pkcs7-mime');
    this.setMimeType('.p7s', 'application/pkcs7-signature');
    this.setMimeType('.cer', 'application/pkix-cert');
    this.setMimeType('.crl', 'application/pkix-crl');
    this.setMimeType('.pkipath', 'application/pkix-pkipath');
    this.setMimeType('.pki', 'application/pkixcmp');
    this.setMimeType('.pls', 'application/pls+xml');
    this.setMimeType('.ai,.eps,.ps', 'application/postscript');
    this.setMimeType('.cww', 'application/prs.cww');
    this.setMimeType('.rdf', 'application/rdf+xml');
    this.setMimeType('.rif', 'application/reginfo+xml');
    this.setMimeType('.rnc', 'application/relax-ng-compact-syntax');
    this.setMimeType('.rl', 'application/resource-lists+xml');
    this.setMimeType('.rld', 'application/resource-lists-diff+xml');
    this.setMimeType('.rs', 'application/rls-services+xml');
    this.setMimeType('.rsd', 'application/rsd+xml');
    this.setMimeType('.rss', 'application/rss+xml');
    this.setMimeType('.rtf', 'application/rtf');
    this.setMimeType('.sbml', 'application/sbml+xml');
    this.setMimeType('.scq', 'application/scvp-cv-request');
    this.setMimeType('.scs', 'application/scvp-cv-response');
    this.setMimeType('.spq', 'application/scvp-vp-request');
    this.setMimeType('.spp', 'application/scvp-vp-response');
    this.setMimeType('.sdp', 'application/sdp');
    this.setMimeType('.setpay', 'application/set-payment-initiation');
    this.setMimeType('.setreg', 'application/set-registration-initiation');
    this.setMimeType('.shf', 'application/shf+xml');
    this.setMimeType('.smi,.smil', 'application/smil+xml');
    this.setMimeType('.rq', 'application/sparql-query');
    this.setMimeType('.srx', 'application/sparql-results+xml');
    this.setMimeType('.gram', 'application/srgs');
    this.setMimeType('.grxml', 'application/srgs+xml');
    this.setMimeType('.ssml', 'application/ssml+xml');
    this.setMimeType('.plb', 'application/vnd.3gpp.pic-bw-large');
    this.setMimeType('.psb', 'application/vnd.3gpp.pic-bw-small');
    this.setMimeType('.pvb', 'application/vnd.3gpp.pic-bw-var');
    this.setMimeType('.tcap', 'application/vnd.3gpp2.tcap');
    this.setMimeType('.pwn', 'application/vnd.3m.post-it-notes');
    this.setMimeType('.aso', 'application/vnd.accpac.simply.aso');
    this.setMimeType('.imp', 'application/vnd.accpac.simply.imp');
    this.setMimeType('.acu', 'application/vnd.acucobol');
    this.setMimeType('.atc,.acutc', 'application/vnd.acucorp');
    this.setMimeType('.air', 'application/vnd.adobe.air-application-installer-package+zip');
    this.setMimeType('.xdp', 'application/vnd.adobe.xdp+xml');
    this.setMimeType('.xfdf', 'application/vnd.adobe.xfdf');
    this.setMimeType('.azf', 'application/vnd.airzip.filesecure.azf');
    this.setMimeType('.azs', 'application/vnd.airzip.filesecure.azs');
    this.setMimeType('.azw', 'application/vnd.amazon.ebook');
    this.setMimeType('.acc', 'application/vnd.americandynamics.acc');
    this.setMimeType('.ami', 'application/vnd.amiga.ami');
    this.setMimeType('.apk', 'application/vnd.android.package-archive');
    this.setMimeType('.cii', 'application/vnd.anser-web-certificate-issue-initiation');
    this.setMimeType('.fti', 'application/vnd.anser-web-funds-transfer-initiation');
    this.setMimeType('.atx', 'application/vnd.antix.game-component');
    this.setMimeType('.mpkg', 'application/vnd.apple.installer+xml');
    this.setMimeType('.swi', 'application/vnd.arastra.swi');
    this.setMimeType('.aep', 'application/vnd.audiograph');
    this.setMimeType('.mpm', 'application/vnd.blueice.multipass');
    this.setMimeType('.bmi', 'application/vnd.bmi');
    this.setMimeType('.rep', 'application/vnd.businessobjects');
    this.setMimeType('.cdxml', 'application/vnd.chemdraw+xml');
    this.setMimeType('.mmd', 'application/vnd.chipnuts.karaoke-mmd');
    this.setMimeType('.cdy', 'application/vnd.cinderella');
    this.setMimeType('.cla', 'application/vnd.claymore');
    this.setMimeType('.c4g,.c4d,.c4f,.c4p,.c4u', 'application/vnd.clonk.c4group');
    this.setMimeType('.csp', 'application/vnd.commonspace');
    this.setMimeType('.cdbcmsg', 'application/vnd.contact.cmsg');
    this.setMimeType('.cmc', 'application/vnd.cosmocaller');
    this.setMimeType('.clkx', 'application/vnd.crick.clicker');
    this.setMimeType('.clkk', 'application/vnd.crick.clicker.keyboard');
    this.setMimeType('.clkp', 'application/vnd.crick.clicker.palette');
    this.setMimeType('.clkt', 'application/vnd.crick.clicker.template');
    this.setMimeType('.clkw', 'application/vnd.crick.clicker.wordbank');
    this.setMimeType('.wbs', 'application/vnd.criticaltools.wbs+xml');
    this.setMimeType('.pml', 'application/vnd.ctc-posml');
    this.setMimeType('.ppd', 'application/vnd.cups-ppd');
    this.setMimeType('.car', 'application/vnd.curl.car');
    this.setMimeType('.pcurl', 'application/vnd.curl.pcurl');
    this.setMimeType('.rdz', 'application/vnd.data-vision.rdz');
    this.setMimeType('.fe_launch', 'application/vnd.denovo.fcselayout-link');
    this.setMimeType('.dna', 'application/vnd.dna');
    this.setMimeType('.mlp', 'application/vnd.dolby.mlp');
    this.setMimeType('.dpg', 'application/vnd.dpgraph');
    this.setMimeType('.dfac', 'application/vnd.dreamfactory');
    this.setMimeType('.geo', 'application/vnd.dynageo');
    this.setMimeType('.mag', 'application/vnd.ecowin.chart');
    this.setMimeType('.nml', 'application/vnd.enliven');
    this.setMimeType('.esf', 'application/vnd.epson.esf');
    this.setMimeType('.msf', 'application/vnd.epson.msf');
    this.setMimeType('.qam', 'application/vnd.epson.quickanime');
    this.setMimeType('.slt', 'application/vnd.epson.salt');
    this.setMimeType('.ssf', 'application/vnd.epson.ssf');
    this.setMimeType('.es3,.et3', 'application/vnd.eszigno3+xml');
    this.setMimeType('.ez2', 'application/vnd.ezpix-album');
    this.setMimeType('.ez3', 'application/vnd.ezpix-package');
    this.setMimeType('.fdf', 'application/vnd.fdf');
    this.setMimeType('.mseed', 'application/vnd.fdsn.mseed');
    this.setMimeType('.seed,.dataless', 'application/vnd.fdsn.seed');
    this.setMimeType('.gph', 'application/vnd.flographit');
    this.setMimeType('.ftc', 'application/vnd.fluxtime.clip');
    this.setMimeType('.fm,.frame,.maker,.book', 'application/vnd.framemaker');
    this.setMimeType('.fnc', 'application/vnd.frogans.fnc');
    this.setMimeType('.ltf', 'application/vnd.frogans.ltf');
    this.setMimeType('.fsc', 'application/vnd.fsc.weblaunch');
    this.setMimeType('.oas', 'application/vnd.fujitsu.oasys');
    this.setMimeType('.oa2', 'application/vnd.fujitsu.oasys2');
    this.setMimeType('.oa3', 'application/vnd.fujitsu.oasys3');
    this.setMimeType('.fg5', 'application/vnd.fujitsu.oasysgp');
    this.setMimeType('.bh2', 'application/vnd.fujitsu.oasysprs');
    this.setMimeType('.ddd', 'application/vnd.fujixerox.ddd');
    this.setMimeType('.xdw', 'application/vnd.fujixerox.docuworks');
    this.setMimeType('.xbd', 'application/vnd.fujixerox.docuworks.binder');
    this.setMimeType('.fzs', 'application/vnd.fuzzysheet');
    this.setMimeType('.txd', 'application/vnd.genomatix.tuxedo');
    this.setMimeType('.ggb', 'application/vnd.geogebra.file');
    this.setMimeType('.ggt', 'application/vnd.geogebra.tool');
    this.setMimeType('.gex,.gre', 'application/vnd.geometry-explorer');
    this.setMimeType('.gmx', 'application/vnd.gmx');
    this.setMimeType('.kml', 'application/vnd.google-earth.kml+xml');
    this.setMimeType('.kmz', 'application/vnd.google-earth.kmz');
    this.setMimeType('.gqf,.gqs', 'application/vnd.grafeq');
    this.setMimeType('.gac', 'application/vnd.groove-account');
    this.setMimeType('.ghf', 'application/vnd.groove-help');
    this.setMimeType('.gim', 'application/vnd.groove-identity-message');
    this.setMimeType('.grv', 'application/vnd.groove-injector');
    this.setMimeType('.gtm', 'application/vnd.groove-tool-message');
    this.setMimeType('.tpl', 'application/vnd.groove-tool-template');
    this.setMimeType('.vcg', 'application/vnd.groove-vcard');
    this.setMimeType('.zmm', 'application/vnd.handheld-entertainment+xml');
    this.setMimeType('.hbci', 'application/vnd.hbci');
    this.setMimeType('.les', 'application/vnd.hhe.lesson-player');
    this.setMimeType('.hpgl', 'application/vnd.hp-hpgl');
    this.setMimeType('.hpid', 'application/vnd.hp-hpid');
    this.setMimeType('.hps', 'application/vnd.hp-hps');
    this.setMimeType('.jlt', 'application/vnd.hp-jlyt');
    this.setMimeType('.pcl', 'application/vnd.hp-pcl');
    this.setMimeType('.pclxl', 'application/vnd.hp-pclxl');
    this.setMimeType('.sfd-hdstx', 'application/vnd.hydrostatix.sof-data');
    this.setMimeType('.x3d', 'application/vnd.hzn-3d-crossword');
    this.setMimeType('.mpy', 'application/vnd.ibm.minipay');
    this.setMimeType('.afp,.listafp,.list3820', 'application/vnd.ibm.modcap');
    this.setMimeType('.irm', 'application/vnd.ibm.rights-management');
    this.setMimeType('.sc', 'application/vnd.ibm.secure-container');
    this.setMimeType('.icc,.icm', 'application/vnd.iccprofile');
    this.setMimeType('.igl', 'application/vnd.igloader');
    this.setMimeType('.ivp', 'application/vnd.immervision-ivp');
    this.setMimeType('.ivu', 'application/vnd.immervision-ivu');
    this.setMimeType('.xpw,.xpx', 'application/vnd.intercon.formnet');
    this.setMimeType('.qbo', 'application/vnd.intu.qbo');
    this.setMimeType('.qfx', 'application/vnd.intu.qfx');
    this.setMimeType('.rcprofile', 'application/vnd.ipunplugged.rcprofile');
    this.setMimeType('.irp', 'application/vnd.irepository.package+xml');
    this.setMimeType('.xpr', 'application/vnd.is-xpr');
    this.setMimeType('.jam', 'application/vnd.jam');
    this.setMimeType('.rms', 'application/vnd.jcp.javame.midlet-rms');
    this.setMimeType('.jisp', 'application/vnd.jisp');
    this.setMimeType('.joda', 'application/vnd.joost.joda-archive');
    this.setMimeType('.ktz,.ktr', 'application/vnd.kahootz');
    this.setMimeType('.karbon', 'application/vnd.kde.karbon');
    this.setMimeType('.chrt', 'application/vnd.kde.kchart');
    this.setMimeType('.kfo', 'application/vnd.kde.kformula');
    this.setMimeType('.flw', 'application/vnd.kde.kivio');
    this.setMimeType('.kon', 'application/vnd.kde.kontour');
    this.setMimeType('.kpr,.kpt', 'application/vnd.kde.kpresenter');
    this.setMimeType('.ksp', 'application/vnd.kde.kspread');
    this.setMimeType('.kwd,.kwt', 'application/vnd.kde.kword');
    this.setMimeType('.htke', 'application/vnd.kenameaapp');
    this.setMimeType('.kia', 'application/vnd.kidspiration');
    this.setMimeType('.kne,.knp', 'application/vnd.kinar');
    this.setMimeType('.skp,.skd,.skt,.skm', 'application/vnd.koan');
    this.setMimeType('.sse', 'application/vnd.kodak-descriptor');
    this.setMimeType('.lbd', 'application/vnd.llamagraphics.life-balance.desktop');
    this.setMimeType('.lbe', 'application/vnd.llamagraphics.life-balance.exchange+xml');
    this.setMimeType('.123', 'application/vnd.lotus-1-2-3');
    this.setMimeType('.apr', 'application/vnd.lotus-approach');
    this.setMimeType('.pre', 'application/vnd.lotus-freelance');
    this.setMimeType('.nsf', 'application/vnd.lotus-notes');
    this.setMimeType('.org', 'application/vnd.lotus-organizer');
    this.setMimeType('.scm', 'application/vnd.lotus-screencam');
    this.setMimeType('.lwp', 'application/vnd.lotus-wordpro');
    this.setMimeType('.portpkg', 'application/vnd.macports.portpkg');
    this.setMimeType('.mcd', 'application/vnd.mcd');
    this.setMimeType('.mc1', 'application/vnd.medcalcdata');
    this.setMimeType('.cdkey', 'application/vnd.mediastation.cdkey');
    this.setMimeType('.mwf', 'application/vnd.mfer');
    this.setMimeType('.mfm', 'application/vnd.mfmp');
    this.setMimeType('.flo', 'application/vnd.micrografx.flo');
    this.setMimeType('.igx', 'application/vnd.micrografx.igx');
    this.setMimeType('.mif', 'application/vnd.mif');
    this.setMimeType('.daf', 'application/vnd.mobius.daf');
    this.setMimeType('.dis', 'application/vnd.mobius.dis');
    this.setMimeType('.mbk', 'application/vnd.mobius.mbk');
    this.setMimeType('.mqy', 'application/vnd.mobius.mqy');
    this.setMimeType('.msl', 'application/vnd.mobius.msl');
    this.setMimeType('.plc', 'application/vnd.mobius.plc');
    this.setMimeType('.txf', 'application/vnd.mobius.txf');
    this.setMimeType('.mpn', 'application/vnd.mophun.application');
    this.setMimeType('.mpc', 'application/vnd.mophun.certificate');
    this.setMimeType('.xul', 'application/vnd.mozilla.xul+xml');
    this.setMimeType('.cil', 'application/vnd.ms-artgalry');
    this.setMimeType('.cab', 'application/vnd.ms-cab-compressed');
    this.setMimeType('.xls,.xlm,.xla,.xlc,.xlt,.xlw', 'application/vnd.ms-excel');
    this.setMimeType('.xlam', 'application/vnd.ms-excel.addin.macroenabled.12');
    this.setMimeType('.xlsb', 'application/vnd.ms-excel.sheet.binary.macroenabled.12');
    this.setMimeType('.xlsm', 'application/vnd.ms-excel.sheet.macroenabled.12');
    this.setMimeType('.xltm', 'application/vnd.ms-excel.template.macroenabled.12');
    this.setMimeType('.eot', 'application/vnd.ms-fontobject');
    this.setMimeType('.chm', 'application/vnd.ms-htmlhelp');
    this.setMimeType('.ims', 'application/vnd.ms-ims');
    this.setMimeType('.lrm', 'application/vnd.ms-lrm');
    this.setMimeType('.cat', 'application/vnd.ms-pki.seccat');
    this.setMimeType('.stl', 'application/vnd.ms-pki.stl');
    this.setMimeType('.ppt,.pps,.pot', 'application/vnd.ms-powerpoint');
    this.setMimeType('.ppam', 'application/vnd.ms-powerpoint.addin.macroenabled.12');
    this.setMimeType('.pptm', 'application/vnd.ms-powerpoint.presentation.macroenabled.12');
    this.setMimeType('.sldm', 'application/vnd.ms-powerpoint.slide.macroenabled.12');
    this.setMimeType('.ppsm', 'application/vnd.ms-powerpoint.slideshow.macroenabled.12');
    this.setMimeType('.potm', 'application/vnd.ms-powerpoint.template.macroenabled.12');
    this.setMimeType('.mpp,.mpt', 'application/vnd.ms-project');
    this.setMimeType('.docm', 'application/vnd.ms-word.document.macroenabled.12');
    this.setMimeType('.dotm', 'application/vnd.ms-word.template.macroenabled.12');
    this.setMimeType('.wps,.wks,.wcm,.wdb', 'application/vnd.ms-works');
    this.setMimeType('.wpl', 'application/vnd.ms-wpl');
    this.setMimeType('.xps', 'application/vnd.ms-xpsdocument');
    this.setMimeType('.mseq', 'application/vnd.mseq');
    this.setMimeType('.mus', 'application/vnd.musician');
    this.setMimeType('.msty', 'application/vnd.muvee.style');
    this.setMimeType('.nlu', 'application/vnd.neurolanguage.nlu');
    this.setMimeType('.nnd', 'application/vnd.noblenet-directory');
    this.setMimeType('.nns', 'application/vnd.noblenet-sealer');
    this.setMimeType('.nnw', 'application/vnd.noblenet-web');
    this.setMimeType('.ngdat', 'application/vnd.nokia.n-gage.data');
    this.setMimeType('.n-gage', 'application/vnd.nokia.n-gage.symbian.install');
    this.setMimeType('.rpst', 'application/vnd.nokia.radio-preset');
    this.setMimeType('.rpss', 'application/vnd.nokia.radio-presets');
    this.setMimeType('.edm', 'application/vnd.novadigm.edm');
    this.setMimeType('.edx', 'application/vnd.novadigm.edx');
    this.setMimeType('.ext', 'application/vnd.novadigm.ext');
    this.setMimeType('.odc', 'application/vnd.oasis.opendocument.chart');
    this.setMimeType('.otc', 'application/vnd.oasis.opendocument.chart-template');
    this.setMimeType('.odb', 'application/vnd.oasis.opendocument.database');
    this.setMimeType('.odf', 'application/vnd.oasis.opendocument.formula');
    this.setMimeType('.odft', 'application/vnd.oasis.opendocument.formula-template');
    this.setMimeType('.odg', 'application/vnd.oasis.opendocument.graphics');
    this.setMimeType('.otg', 'application/vnd.oasis.opendocument.graphics-template');
    this.setMimeType('.odi', 'application/vnd.oasis.opendocument.image');
    this.setMimeType('.oti', 'application/vnd.oasis.opendocument.image-template');
    this.setMimeType('.odp', 'application/vnd.oasis.opendocument.presentation');
    this.setMimeType('.ods', 'application/vnd.oasis.opendocument.spreadsheet');
    this.setMimeType('.ots', 'application/vnd.oasis.opendocument.spreadsheet-template');
    this.setMimeType('.odt', 'application/vnd.oasis.opendocument.text');
    this.setMimeType('.otm', 'application/vnd.oasis.opendocument.text-master');
    this.setMimeType('.ott', 'application/vnd.oasis.opendocument.text-template');
    this.setMimeType('.oth', 'application/vnd.oasis.opendocument.text-web');
    this.setMimeType('.xo', 'application/vnd.olpc-sugar');
    this.setMimeType('.dd2', 'application/vnd.oma.dd2+xml');
    this.setMimeType('.oxt', 'application/vnd.openofficeorg.extension');
    this.setMimeType(
      '.pptx',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    );
    this.setMimeType('.sldx', 'application/vnd.openxmlformats-officedocument.presentationml.slide');
    this.setMimeType(
      '.ppsx',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
    );
    this.setMimeType(
      '.potx',
      'application/vnd.openxmlformats-officedocument.presentationml.template'
    );
    this.setMimeType('.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    this.setMimeType(
      '.xltx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
    );
    this.setMimeType(
      '.docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
    this.setMimeType(
      '.dotx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
    );
    this.setMimeType('.dp', 'application/vnd.osgi.dp');
    this.setMimeType('.pdb,.pqa,.oprc', 'application/vnd.palm');
    this.setMimeType('.str', 'application/vnd.pg.format');
    this.setMimeType('.ei6', 'application/vnd.pg.osasli');
    this.setMimeType('.efif', 'application/vnd.picsel');
    this.setMimeType('.plf', 'application/vnd.pocketlearn');
    this.setMimeType('.pbd', 'application/vnd.powerbuilder6');
    this.setMimeType('.box', 'application/vnd.previewsystems.box');
    this.setMimeType('.mgz', 'application/vnd.proteus.magazine');
    this.setMimeType('.qps', 'application/vnd.publishare-delta-tree');
    this.setMimeType('.ptid', 'application/vnd.pvi.ptid1');
    this.setMimeType('.qxd,.qxt,.qwd,.qwt,.qxl,.qxb', 'application/vnd.quark.quarkxpress');
    this.setMimeType('.mxl', 'application/vnd.recordare.musicxml');
    this.setMimeType('.musicxml', 'application/vnd.recordare.musicxml+xml');
    this.setMimeType('.cod', 'application/vnd.rim.cod');
    this.setMimeType('.rm', 'application/vnd.rn-realmedia');
    this.setMimeType('.link66', 'application/vnd.route66.link66+xml');
    this.setMimeType('.see', 'application/vnd.seemail');
    this.setMimeType('.sema', 'application/vnd.sema');
    this.setMimeType('.semd', 'application/vnd.semd');
    this.setMimeType('.semf', 'application/vnd.semf');
    this.setMimeType('.ifm', 'application/vnd.shana.informed.formdata');
    this.setMimeType('.itp', 'application/vnd.shana.informed.formtemplate');
    this.setMimeType('.iif', 'application/vnd.shana.informed.interchange');
    this.setMimeType('.ipk', 'application/vnd.shana.informed.package');
    this.setMimeType('.twd,.twds', 'application/vnd.simtech-mindmapper');
    this.setMimeType('.mmf', 'application/vnd.smaf');
    this.setMimeType('.teacher', 'application/vnd.smart.teacher');
    this.setMimeType('.sdkm,.sdkd', 'application/vnd.solent.sdkm+xml');
    this.setMimeType('.dxp', 'application/vnd.spotfire.dxp');
    this.setMimeType('.sfs', 'application/vnd.spotfire.sfs');
    this.setMimeType('.sdc', 'application/vnd.stardivision.calc');
    this.setMimeType('.sda', 'application/vnd.stardivision.draw');
    this.setMimeType('.sdd', 'application/vnd.stardivision.impress');
    this.setMimeType('.smf', 'application/vnd.stardivision.math');
    this.setMimeType('.sdw', 'application/vnd.stardivision.writer');
    this.setMimeType('.vor', 'application/vnd.stardivision.writer');
    this.setMimeType('.sgl', 'application/vnd.stardivision.writer-global');
    this.setMimeType('.sxc', 'application/vnd.sun.xml.calc');
    this.setMimeType('.stc', 'application/vnd.sun.xml.calc.template');
    this.setMimeType('.sxd', 'application/vnd.sun.xml.draw');
    this.setMimeType('.std', 'application/vnd.sun.xml.draw.template');
    this.setMimeType('.sxi', 'application/vnd.sun.xml.impress');
    this.setMimeType('.sti', 'application/vnd.sun.xml.impress.template');
    this.setMimeType('.sxm', 'application/vnd.sun.xml.math');
    this.setMimeType('.sxw', 'application/vnd.sun.xml.writer');
    this.setMimeType('.sxg', 'application/vnd.sun.xml.writer.global');
    this.setMimeType('.stw', 'application/vnd.sun.xml.writer.template');
    this.setMimeType('.sus,.susp', 'application/vnd.sus-calendar');
    this.setMimeType('.svd', 'application/vnd.svd');
    this.setMimeType('.sis,.sisx', 'application/vnd.symbian.install');
    this.setMimeType('.xsm', 'application/vnd.syncml+xml');
    this.setMimeType('.bdm', 'application/vnd.syncml.dm+wbxml');
    this.setMimeType('.xdm', 'application/vnd.syncml.dm+xml');
    this.setMimeType('.tao', 'application/vnd.tao.intent-module-archive');
    this.setMimeType('.tmo', 'application/vnd.tmobile-livetv');
    this.setMimeType('.tpt', 'application/vnd.trid.tpt');
    this.setMimeType('.mxs', 'application/vnd.triscape.mxs');
    this.setMimeType('.tra', 'application/vnd.trueapp');
    this.setMimeType('.ufd,.ufdl', 'application/vnd.ufdl');
    this.setMimeType('.utz', 'application/vnd.uiq.theme');
    this.setMimeType('.umj', 'application/vnd.umajin');
    this.setMimeType('.unityweb', 'application/vnd.unity');
    this.setMimeType('.uoml', 'application/vnd.uoml+xml');
    this.setMimeType('.vcx', 'application/vnd.vcx');
    this.setMimeType('.vsd,.vst,.vss,.vsw', 'application/vnd.visio');
    this.setMimeType('.vis', 'application/vnd.visionary');
    this.setMimeType('.vsf', 'application/vnd.vsf');
    this.setMimeType('.wbxml', 'application/vnd.wap.wbxml');
    this.setMimeType('.wmlc', 'application/vnd.wap.wmlc');
    this.setMimeType('.wmlsc', 'application/vnd.wap.wmlscriptc');
    this.setMimeType('.wtb', 'application/vnd.webturbo');
    this.setMimeType('.wpd', 'application/vnd.wordperfect');
    this.setMimeType('.wqd', 'application/vnd.wqd');
    this.setMimeType('.stf', 'application/vnd.wt.stf');
    this.setMimeType('.xar', 'application/vnd.xara');
    this.setMimeType('.xfdl', 'application/vnd.xfdl');
    this.setMimeType('.hvd', 'application/vnd.yamaha.hv-dic');
    this.setMimeType('.hvs', 'application/vnd.yamaha.hv-script');
    this.setMimeType('.hvp', 'application/vnd.yamaha.hv-voice');
    this.setMimeType('.osf', 'application/vnd.yamaha.openscoreformat');
    this.setMimeType('.osfpvg', 'application/vnd.yamaha.openscoreformat.osfpvg+xml');
    this.setMimeType('.saf', 'application/vnd.yamaha.smaf-audio');
    this.setMimeType('.spf', 'application/vnd.yamaha.smaf-phrase');
    this.setMimeType('.cmp', 'application/vnd.yellowriver-custom-menu');
    this.setMimeType('.zir,.zirz', 'application/vnd.zul');
    this.setMimeType('.zaz', 'application/vnd.zzazz.deck+xml');
    this.setMimeType('.vxml', 'application/voicexml+xml');
    this.setMimeType('.hlp', 'application/winhlp');
    this.setMimeType('.wsdl', 'application/wsdl+xml');
    this.setMimeType('.wspolicy', 'application/wspolicy+xml');
    this.setMimeType('.abw', 'application/x-abiword');
    this.setMimeType('.ace', 'application/x-ace-compressed');
    this.setMimeType('.aab,.x32,.u32,.vox', 'application/x-authorware-bin');
    this.setMimeType('.aam', 'application/x-authorware-map');
    this.setMimeType('.aas', 'application/x-authorware-seg');
    this.setMimeType('.bcpio', 'application/x-bcpio');
    this.setMimeType('.torrent', 'application/x-bittorrent');
    this.setMimeType('.bz', 'application/x-bzip');
    this.setMimeType('.bz2,.boz', 'application/x-bzip2');
    this.setMimeType('.vcd', 'application/x-cdlink');
    this.setMimeType('.chat', 'application/x-chat');
    this.setMimeType('.pgn', 'application/x-chess-pgn');
    this.setMimeType('.cpio', 'application/x-cpio');
    this.setMimeType('.csh', 'application/x-csh');
    this.setMimeType('.deb,.udeb', 'application/x-debian-package');
    this.setMimeType('.dir,.dcr,.dxr,.cst,.cct,.cxt,.w3d,.fgd,.swa', 'application/x-director');
    this.setMimeType('.wad', 'application/x-doom');
    this.setMimeType('.ncx', 'application/x-dtbncx+xml');
    this.setMimeType('.dtb', 'application/x-dtbook+xml');
    this.setMimeType('.res', 'application/x-dtbresource+xml');
    this.setMimeType('.dvi', 'application/x-dvi');
    this.setMimeType('.bdf', 'application/x-font-bdf');
    this.setMimeType('.gsf', 'application/x-font-ghostscript');
    this.setMimeType('.psf', 'application/x-font-linux-psf');
    this.setMimeType('.otf', 'application/x-font-otf');
    this.setMimeType('.pcf', 'application/x-font-pcf');
    this.setMimeType('.snf', 'application/x-font-snf');
    this.setMimeType('.ttf,.ttc', 'application/x-font-ttf');
    this.setMimeType('.woff', 'application/font-woff');
    this.setMimeType('.pfa,.pfb,.pfm,.afm', 'application/x-font-type1');
    this.setMimeType('.spl', 'application/x-futuresplash');
    this.setMimeType('.gnumeric', 'application/x-gnumeric');
    this.setMimeType('.gtar', 'application/x-gtar');
    this.setMimeType('.hdf', 'application/x-hdf');
    this.setMimeType('.jnlp', 'application/x-java-jnlp-file');
    this.setMimeType('.latex', 'application/x-latex');
    this.setMimeType('.prc,.mobi', 'application/x-mobipocket-ebook');
    this.setMimeType('.application', 'application/x-ms-application');
    this.setMimeType('.wmd', 'application/x-ms-wmd');
    this.setMimeType('.wmz', 'application/x-ms-wmz');
    this.setMimeType('.xbap', 'application/x-ms-xbap');
    this.setMimeType('.mdb', 'application/x-msaccess');
    this.setMimeType('.obd', 'application/x-msbinder');
    this.setMimeType('.crd', 'application/x-mscardfile');
    this.setMimeType('.clp', 'application/x-msclip');
    this.setMimeType('.exe,.dll,.com,.bat,.msi', 'application/x-msdownload');
    this.setMimeType('.mvb,.m13,.m14', 'application/x-msmediaview');
    this.setMimeType('.wmf', 'application/x-msmetafile');
    this.setMimeType('.mny', 'application/x-msmoney');
    this.setMimeType('.pub', 'application/x-mspublisher');
    this.setMimeType('.scd', 'application/x-msschedule');
    this.setMimeType('.trm', 'application/x-msterminal');
    this.setMimeType('.wri', 'application/x-mswrite');
    this.setMimeType('.nc,.cdf', 'application/x-netcdf');
    this.setMimeType('.p12,.pfx', 'application/x-pkcs12');
    this.setMimeType('.p7b,.spc', 'application/x-pkcs7-certificates');
    this.setMimeType('.p7r', 'application/x-pkcs7-certreqresp');
    this.setMimeType('.rar', 'application/x-rar-compressed');
    this.setMimeType('.sh', 'application/x-sh');
    this.setMimeType('.shar', 'application/x-shar');
    this.setMimeType('.swf', 'application/x-shockwave-flash');
    this.setMimeType('.xap', 'application/x-silverlight-app');
    this.setMimeType('.sit', 'application/x-stuffit');
    this.setMimeType('.sitx', 'application/x-stuffitx');
    this.setMimeType('.sv4cpio', 'application/x-sv4cpio');
    this.setMimeType('.sv4crc', 'application/x-sv4crc');
    this.setMimeType('.tar', 'application/x-tar');
    this.setMimeType('.tcl', 'application/x-tcl');
    this.setMimeType('.tex', 'application/x-tex');
    this.setMimeType('.tfm', 'application/x-tex-tfm');
    this.setMimeType('.texinfo,.texi', 'application/x-texinfo');
    this.setMimeType('.ustar', 'application/x-ustar');
    this.setMimeType('.src', 'application/x-wais-source');
    this.setMimeType('.der,.crt', 'application/x-x509-ca-cert');
    this.setMimeType('.fig', 'application/x-xfig');
    this.setMimeType('.xpi', 'application/x-xpinstall');
    this.setMimeType('.xenc', 'application/xenc+xml');
    this.setMimeType('.xhtml,.xht', 'application/xhtml+xml');
    this.setMimeType('.xml,.xsl', 'application/xml');
    this.setMimeType('.dtd', 'application/xml-dtd');
    this.setMimeType('.xop', 'application/xop+xml');
    this.setMimeType('.xslt', 'application/xslt+xml');
    this.setMimeType('.xspf', 'application/xspf+xml');
    this.setMimeType('.mxml,.xhvml,.xvml,.xvm', 'application/xv+xml');
    this.setMimeType('.zip', 'application/zip');
    this.setMimeType('.adp', 'audio/adpcm');
    this.setMimeType('.au,.snd', 'audio/basic');
    this.setMimeType('.mid,.midi,.kar,.rmi', 'audio/midi');
    this.setMimeType('.mp4a', 'audio/mp4');
    this.setMimeType('.m4a,.m4p', 'audio/mp4a-latm');
    this.setMimeType('.mpga,.mp2,.mp2a,.mp3,.m2a,.m3a', 'audio/mpeg');
    this.setMimeType('.oga,.ogg,.spx', 'audio/ogg');
    this.setMimeType('.eol', 'audio/vnd.digital-winds');
    this.setMimeType('.dts', 'audio/vnd.dts');
    this.setMimeType('.dtshd', 'audio/vnd.dts.hd');
    this.setMimeType('.lvp', 'audio/vnd.lucent.voice');
    this.setMimeType('.pya', 'audio/vnd.ms-playready.media.pya');
    this.setMimeType('.ecelp4800', 'audio/vnd.nuera.ecelp4800');
    this.setMimeType('.ecelp7470', 'audio/vnd.nuera.ecelp7470');
    this.setMimeType('.ecelp9600', 'audio/vnd.nuera.ecelp9600');
    this.setMimeType('.aac', 'audio/x-aac');
    this.setMimeType('.aif,.aiff,.aifc', 'audio/x-aiff');
    this.setMimeType('.m3u', 'audio/x-mpegurl');
    this.setMimeType('.wax', 'audio/x-ms-wax');
    this.setMimeType('.wma', 'audio/x-ms-wma');
    this.setMimeType('.ram,.ra', 'audio/x-pn-realaudio');
    this.setMimeType('.rmp', 'audio/x-pn-realaudio-plugin');
    this.setMimeType('.wav', 'audio/x-wav');
    this.setMimeType('.cdx', 'chemical/x-cdx');
    this.setMimeType('.cif', 'chemical/x-cif');
    this.setMimeType('.cmdf', 'chemical/x-cmdf');
    this.setMimeType('.cml', 'chemical/x-cml');
    this.setMimeType('.csml', 'chemical/x-csml');
    this.setMimeType('.xyz', 'chemical/x-xyz');
    this.setMimeType('.bmp', 'image/bmp');
    this.setMimeType('.cgm', 'image/cgm');
    this.setMimeType('.g3', 'image/g3fax');
    this.setMimeType('.gif', 'image/gif');
    this.setMimeType('.ief', 'image/ief');
    this.setMimeType('.jp2', 'image/jp2');
    this.setMimeType('.jpeg,.jpg,.jpe', 'image/jpeg');
    this.setMimeType('.pict,.pic,.pct', 'image/pict');
    this.setMimeType('.png', 'image/png');
    this.setMimeType('.btif', 'image/prs.btif');
    this.setMimeType('.svg,.svgz', 'image/svg+xml');
    this.setMimeType('.tiff,.tif', 'image/tiff');
    this.setMimeType('.psd', 'image/vnd.adobe.photoshop');
    this.setMimeType('.djvu,.djv', 'image/vnd.djvu');
    this.setMimeType('.dwg', 'image/vnd.dwg');
    this.setMimeType('.dxf', 'image/vnd.dxf');
    this.setMimeType('.fbs', 'image/vnd.fastbidsheet');
    this.setMimeType('.fpx', 'image/vnd.fpx');
    this.setMimeType('.fst', 'image/vnd.fst');
    this.setMimeType('.mmr', 'image/vnd.fujixerox.edmics-mmr');
    this.setMimeType('.rlc', 'image/vnd.fujixerox.edmics-rlc');
    this.setMimeType('.mdi', 'image/vnd.ms-modi');
    this.setMimeType('.npx', 'image/vnd.net-fpx');
    this.setMimeType('.wbmp', 'image/vnd.wap.wbmp');
    this.setMimeType('.xif', 'image/vnd.xiff');
    this.setMimeType('.ras', 'image/x-cmu-raster');
    this.setMimeType('.cmx', 'image/x-cmx');
    this.setMimeType('.fh,.fhc,.fh4,.fh5,.fh7', 'image/x-freehand');
    this.setMimeType('.ico', 'image/x-icon');
    this.setMimeType('.pntg,.pnt,.mac', 'image/x-macpaint');
    this.setMimeType('.pcx', 'image/x-pcx');
    this.setMimeType('.pnm', 'image/x-portable-anymap');
    this.setMimeType('.pbm', 'image/x-portable-bitmap');
    this.setMimeType('.pgm', 'image/x-portable-graymap');
    this.setMimeType('.ppm', 'image/x-portable-pixmap');
    this.setMimeType('.qtif,.qti', 'image/x-quicktime');
    this.setMimeType('.rgb', 'image/x-rgb');
    this.setMimeType('.xbm', 'image/x-xbitmap');
    this.setMimeType('.xpm', 'image/x-xpixmap');
    this.setMimeType('.xwd', 'image/x-xwindowdump');
    this.setMimeType('.eml,.mime', 'message/rfc822');
    this.setMimeType('.igs,.iges', 'model/iges');
    this.setMimeType('.msh,.mesh,.silo', 'model/mesh');
    this.setMimeType('.dwf', 'model/vnd.dwf');
    this.setMimeType('.gdl', 'model/vnd.gdl');
    this.setMimeType('.gtw', 'model/vnd.gtw');
    this.setMimeType('.mts', 'model/vnd.mts');
    this.setMimeType('.vtu', 'model/vnd.vtu');
    this.setMimeType('.wrl,.vrml', 'model/vrml');
    this.setMimeType('.ics,.ifb', 'text/calendar');
    this.setMimeType('.css', 'text/css');
    this.setMimeType('.csv', 'text/csv');
    this.setMimeType('.html,.htm', 'text/html');
    this.setMimeType('.txt,.text,.conf,.def,.list,.log,.in', 'text/plain');
    this.setMimeType('.dsc', 'text/prs.lines.tag');
    this.setMimeType('.rtx', 'text/richtext');
    this.setMimeType('.sgml,.sgm', 'text/sgml');
    this.setMimeType('.tsv', 'text/tab-separated-values');
    this.setMimeType('.t,.tr,.roff,.man,.me,.ms', 'text/troff');
    this.setMimeType('.uri,.uris,.urls', 'text/uri-list');
    this.setMimeType('.curl', 'text/vnd.curl');
    this.setMimeType('.dcurl', 'text/vnd.curl.dcurl');
    this.setMimeType('.scurl', 'text/vnd.curl.scurl');
    this.setMimeType('.mcurl', 'text/vnd.curl.mcurl');
    this.setMimeType('.fly', 'text/vnd.fly');
    this.setMimeType('.flx', 'text/vnd.fmi.flexstor');
    this.setMimeType('.gv', 'text/vnd.graphviz');
    this.setMimeType('.3dml', 'text/vnd.in3d.3dml');
    this.setMimeType('.spot', 'text/vnd.in3d.spot');
    this.setMimeType('.jad', 'text/vnd.sun.j2me.app-descriptor');
    this.setMimeType('.wml', 'text/vnd.wap.wml');
    this.setMimeType('.wmls', 'text/vnd.wap.wmlscript');
    this.setMimeType('.s,.asm', 'text/x-asm');
    this.setMimeType('.c,.cc,.cxx,.cpp,.h,.hh,.dic', 'text/x-c');
    this.setMimeType('.f,.for,.f77,.f90', 'text/x-fortran');
    this.setMimeType('.p,.pas', 'text/x-pascal');
    this.setMimeType('.java', 'text/x-java-source');
    this.setMimeType('.etx', 'text/x-setext');
    this.setMimeType('.uu', 'text/x-uuencode');
    this.setMimeType('.vcs', 'text/x-vcalendar');
    this.setMimeType('.vcf', 'text/x-vcard');
    this.setMimeType('.3gp', 'video/3gpp');
    this.setMimeType('.3g2', 'video/3gpp2');
    this.setMimeType('.h261', 'video/h261');
    this.setMimeType('.h263', 'video/h263');
    this.setMimeType('.h264', 'video/h264');
    this.setMimeType('.jpgv', 'video/jpeg');
    this.setMimeType('.jpm,.jpgm', 'video/jpm');
    this.setMimeType('.mj2,.mjp2', 'video/mj2');
    this.setMimeType('.mp4,.mp4v,.mpg4,.m4v', 'video/mp4');
    this.setMimeType('.mkv,.mk3d,.mka,.mks', 'video/x-matroska');
    this.setMimeType('.webm', 'video/webm');
    this.setMimeType('.mpeg,.mpg,.mpe,.m1v,.m2v', 'video/mpeg');
    this.setMimeType('.ogv', 'video/ogg');
    this.setMimeType('.qt,.mov', 'video/quicktime');
    this.setMimeType('.fvt', 'video/vnd.fvt');
    this.setMimeType('.mxu,.m4u', 'video/vnd.mpegurl');
    this.setMimeType('.pyv', 'video/vnd.ms-playready.media.pyv');
    this.setMimeType('.viv', 'video/vnd.vivo');
    this.setMimeType('.dv,.dif', 'video/x-dv');
    this.setMimeType('.f4v', 'video/x-f4v');
    this.setMimeType('.fli', 'video/x-fli');
    this.setMimeType('.flv', 'video/x-flv');
    this.setMimeType('.asf,.asx', 'video/x-ms-asf');
    this.setMimeType('.wm', 'video/x-ms-wm');
    this.setMimeType('.wmv', 'video/x-ms-wmv');
    this.setMimeType('.wmx', 'video/x-ms-wmx');
    this.setMimeType('.wvx', 'video/x-ms-wvx');
    this.setMimeType('.avi', 'video/x-msvideo');
    this.setMimeType('.movie', 'video/x-sgi-movie');
    this.setMimeType('.ice', 'x-conference/x-cooltalk');
    this.setMimeType('.indd', 'application/x-indesign');
    this.setMimeType('.dat', 'application/octet-stream');
    this.setMimeType('.gz', 'application/x-gzip');
    this.setMimeType('.tgz', 'application/x-tar');
    this.setMimeType('.tar', 'application/x-tar');
    this.setMimeType('.epub', 'application/epub+zip');
    this.setMimeType('.mobi', 'application/x-mobipocket-ebook');
  }

  setMimeType(extension: string, mimeType: string): void {
    this.mimeTypes.push({
      extension: extension,
      mimeType: mimeType,
    });
  }

  getMimeTypeExtension(mimeType: string): void {
    const result = this.mimeTypes.find((element) => {
      return element.mimeType === mimeType;
    });
    return result ? result.extension : '';
  }

  generateParams(page: number, size: number, search: string, order: string): string {
    let params: string;

    if (page != 0) {
      params = params === undefined || params == '' ? '?cp=' + page : params + '&cp=' + page;
    }

    if (size != 0) {
      params = params === undefined || params == '' ? '?size=' + size : params + '&size=' + size;
    }

    if (search != '') {
      params =
        params === undefined || params == '' ? '?search=' + search : params + '&search=' + search;
    }

    if (order != '') {
      params =
        params === undefined || params == '' ? '?order=' + order : params + '&order=' + order;
    }

    return params;
  }

  getDocTypes(): Observable<any> {
    const endPoint = ADDRESS_DMS + '/supported_types';
    return this.http
      .get(endPoint, this.setRequestOptions())
      .pipe(catchError((error) => this.errorHandler(error)));
  }

  downloadDocById(docId: number) {
    return this.http
      .get(ADDRESS_DMS + '/document/download?id=' + docId, { responseType: 'blob' })
      .pipe(catchError(this.errorHandler));
  }

  downloadDoc(url: string) {
    return this.http.get(url, { responseType: 'blob' }).pipe(catchError(this.errorHandler));
  }

  createFile(blob: Blob, fileName: string, type: string) {
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  download(url: string, fileName: string) {
    this.downloadDoc(url).subscribe((response) => {
      const blob = new Blob([response]);
      this.createFile(blob, fileName, response.type);
    });
  }
  downloadById(id: number, receipt: string, index?: number) {
    this.downloadDocById(id).subscribe(
      (response) => {
        const blob = new Blob([response]);
        if (index) {
          setTimeout(() => {
            this.createFile(blob, receipt, response.type);
          }, index * 150);
        } else {
          this.createFile(blob, receipt, response.type);
        }
      },
      (error) => {
        throw error;
      }
    );
  }
  /**
   *
   * @param file
   * @param params
   * @param params.doc_type
   * @param params.loan_code
   * @param params.borrower_id
   * @param params.loan_id
   * @param params.investor_id
   */
  uploadFile(file, params?: any) {
    const _formData = new FormData();
    _formData.append('file', file);
    const endPoint = ADDRESS_DMS + '/document/upload/single';
    return this.http.post(endPoint, _formData, this.setHttpParams(params)).pipe(
      publishLast(),
      refCount(),
      catchError((err) => observableThrowError(err))
    );
  }

  deleteDmsDoc(doc_id) {
    return this.http.delete(ADDRESS_DMS + '/document/' + doc_id);
  }

  createFacilityDocs(data: any): Observable<any> {
    const endPoint = ADDRESS_FACILITY_DOC;
    const result = this.http
      .post(
        endPoint,
        {
          docs: data,
        },
        this.postHeaderRequest()
      )
      .pipe(
        map((res) => this.responseCheck(res)),
        map(
          (res) =>
            res.id || {
              backendError: true,
              error: res.error,
            }
        ),
        catchError((err) => this.errorHandler(err))
      ) as Observable<any>;
    return result;
  }

  getDocByFacilityCode(facilityCode: string) {
    const endPoint = `${ADDRESS_FACILITY_DOC}/${facilityCode}`;
    const result = this.http.get(endPoint, this.setRequestOptions()).pipe(
      map((res) => this.responseCheck(res)),
      map((res) => res.data),
      catchError((err) => this.errorHandler(err))
    );
    return result;
  }

  removeDoc(id: string) {
    const endPoint = ADDRESS_FACILITY_DOC + '/' + id;
    const result = this.http
      .delete(endPoint, this.postHeaderRequest())
      .pipe(catchError((err) => this.errorHandler(err)));
    return result;
  }

  getFacilityDocTypes(type: string, country: string) {
    const endPoint = ADDRESS_FACILITY_DOC_TYPES;
    const params = {
      type,
      country,
    };
    const result = this.http.get(endPoint, this.setHttpParams(params)).pipe(
      map((res) => this.responseCheck(res)),
      map((res) => res.data),
      catchError((err) => this.errorHandler(err))
    );
    return result;
  }

  updateDocStatus(id, status): Observable<number> {
    const endPoint = ADDRESS_FACILITY_DOC + '/status/' + id;
    return this.http.put(endPoint, { status }, this.setRequestOptions()).pipe(
      map((res) => this.responseCheck(res)),
      catchError((err) => this.errorHandler(err))
    ) as Observable<number>;
  }

  uploadFileBulk(file, doc_type, borrower_id, ref_id) {
    const _formData = new FormData();
    file.forEach((f) => _formData.append('files', f));
    return this.http
      .post(
        `${ADDRESS_DMS}/document/upload/multiple`,
        _formData,
        this.postHeaderRequestUpload(false, {
          doc_type: doc_type,
          ref_id: ref_id,
          borrower_id: borrower_id,
        })
      )
      .pipe(
        publishLast(),
        refCount(),
        catchError((err) => observableThrowError(err))
      );
  }

  patchLoanIdAndCodeToDoc(loanId, loanCode, dmsUuid) {
    const endPoint = ADDRESS_DMS + '/document/' + dmsUuid;
    return this.http.patch(endPoint, { loanId, loanCode }, this.setRequestOptions()).pipe(
      map((res) => this.responseCheck(res)),
      catchError((err) => this.errorHandler(err))
    ) as Observable<number>;
  }

  duplicateInvoiceLoan(docId, loanId, loanCode) {
    const endPoint = ADDRESS_DMS + '/document/' + docId + '/duplicate';
    const result = this.http
      .post(
        endPoint,
        {
          loanId,
          loanCode,
        },
        this.postHeaderRequest()
      )
      .pipe(
        map((res) => this.responseCheck(res)),
        catchError((err) => this.errorHandler(err))
      ) as Observable<number>;
    return result;
  }

  createBlobDocById(docId: string) {
    const url = ADDRESS_DMS + '/document/download?id=' + docId.toString();
    return this.http.get(url, { responseType: 'blob' }).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((error) => {
            this.loadingService.addProcess();
            return error.status === 500 || error.status === 0
              ? observableOf(error)
              : observableThrowError(error);
          }),
          take(3)
        );
      }),
      catchError((error) => this.errorHandler(error))
    );
  }

  uploadFileSingle(file, doc_type) {
    const _formData = new FormData();
    _formData.append('file', file);
    return this.http
      .post(
        `${ADDRESS_DMS}/document/upload/single`,
        _formData,
        this.postHeaderRequestUpload(false, {
          doc_type: doc_type,
        })
      )
      .pipe(
        publishLast(),
        refCount(),
        catchError((err) => observableThrowError(err))
      );
  }

  addSubscribers(docUuidList, memberIds) {
    return this.http
      .post(
        `${ADDRESS_DMS}/subscribers`,
        {
          docUuidList,
          memberIds,
        },
        this.postHeaderRequest()
      )
      .pipe(catchError((err) => observableThrowError(err)));
  }

  getInvoiceFinancingDocTypes() {
    return [this.invoiceDocType];
  }

  getDealerFinancingDocTypes() {
    return [
      this.drawdownDocType,
      this.carPhotosDocType,
      this.inspectionReportsDocType,
      this.vehicleOwnershipCertificationDocType,
      this.paymentProofDocType,
      this.dfOthersDocType,
      this.eautoDocType,
      this.dfSettlementReceipt,
    ];
  }
}
