/* Try to deduce message string from error */
export function getMessage(error: any): string {
  if (typeof error === 'string') {
    return error;
  }

  if (typeof error === 'object' && error !== null) {
    const possibleKeys = ['message', 'msg'];
    const messageKey = possibleKeys.find((key) => !!error[key]);
    return error[messageKey] || JSON.stringify(error);
  }

  return error;
}
