import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Store user specific permission information like screens, rules.
 */
@Injectable()
export class PermissionService {
  private readonly _screens = new BehaviorSubject<string[]>([]);

  readonly screens$ = this._screens.asObservable();

  private get screens(): string[] {
    return this._screens.getValue();
  }

  private set screens(screens: string[]) {
    this._screens.next(screens);
  }

  getScreenAccess() {
    return this.screens;
  }

  setScreenAccess(screens: string[]) {
    this.screens = [...this.screens, ...screens];
  }
}
