import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-minimalizasidebar',
  templateUrl: './minimalizasidebar.component.html',
  styleUrls: ['./minimalizasidebar.component.css']
})
export class MinimalizasidebarComponent implements OnInit {
  clicked(event) {
    event.preventDefault();
    $("body").toggleClass("mini-navbar");
    if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
      // Hide menu in order to smoothly turn on when maximize menu
      $('#side-menu').hide();
      // For smoothly turn on menu
      setTimeout(
        function () {
          $('#side-menu').fadeIn(400);
        }, 200);
    } else if ($('body').hasClass('fixed-sidebar')) {
      $('#side-menu').hide();
      setTimeout(
        function () {
          $('#side-menu').fadeIn(400);
        }, 100);
    } else {
      // Remove all inline style from jquery fadeIn function to reset menu state
      $('#side-menu').removeAttr('style');
    }
  }

  constructor() {}

  ngOnInit() {}

}