import { Injectable } from '@angular/core';

@Injectable()
export class DynamicProductionEnvironmentService {
  defaultEnvironment: string;
  storageEnvironmentKey: string;
  environments: Array<string>;
  constructor(
  ) {
    this.storageEnvironmentKey = 'environment';
    this.defaultEnvironment = 'production';
    this.environments = [
      'production'
    ];
  }

  changeEnvironment(environment: string) {
    if (environment) {
      sessionStorage.setItem(this.storageEnvironmentKey, environment);
    }
  }

  getEnvironment() {
    return sessionStorage.getItem(this.storageEnvironmentKey);
  }

  getEnvironments() {
    return this.environments;
  }
}
