export const companyEntityTypes = {
  perseorangan: 'Perseorangan',
  commanditaireVennootschap: 'Commanditaire Vennootschap',
  perseroanTerbatas: 'Perseroan Terbatas',
  perseroanTerbatasPenanamanModalAsing:
    'Perseroan Terbatas Penanaman Modal Asing',
  kantorPerwakilanPerusahaanAsing: 'Kantor Perwakilan Perusahaan Asing',
  kantorPerwakilanPerusahaanPerdaganganAsing:
    'Kantor Perwakilan Perusahaan Perdagangan Asing',
  limitedLiabilityPartnerships: 'Limited Liability Partnerships',
  tradeUnions: 'Trade Unions',
  limitedPartnerships: 'Limited Partnerships',
  internationalOrganisation: 'International Organisation',
  foreignMilitaryUnits: 'Foreign Military Units',
  insuranceRepresentativeOffices: 'Insurance Representative Offices',
  hospitals: 'Hospitals',
  business: 'Business',
  highCommissionsOrEmbassies: 'High Commissions or Embassies',
  otherFinancialRepresentativeOffices: 'Other Financial Representative Offices',
  unregisteredForeignEntities: 'Unregistered Foreign Entities',
  mutualBenefitOrganisation: 'Mutual Benefit Organisation',
  managementCorporations: 'Management Corporations',
  unregisteredLocalEntities: 'Unregistered Local Entities',
  bothMedicalAndDentalClinic: 'Both Medical and Dental Clinic',
  organsOfStateMinistriesAndDepartments:
    'Organs of State, Ministries and Departments',
  subsidiaryManagementCorporations: 'Subsidiary Management Corporations',
  statutoryBoards: 'Statutory Boards',
  maternityHomes: 'Maternity Homes',
  internationalEducationLanguageCentres:
    'International Education Language Centres',
  madrasahs: 'Madrasahs',
  societies: 'Societies',
  charitiesAndInstitutionOfAPublicCharacter:
    'Charities and Institution of a Public Character',
  onlyDentalClinics: 'Only Dental Clinics',
  mosques: 'Mosques',
  commercialHome: 'Commercial Home',
  auditFirm: 'Audit Firm',
  clinicalLaboratories: 'Clinical Laboratories',
  onlyMedicalClinic: 'Only Medical Clinic',
  governmentAndGovernmentAidedSchools:
    'Government and Government-aided Schools',
  consulateGeneralOrConsulate: 'Consulate-General or Consulate',
  townCouncils: 'Town Councils',
  cooperativeSocieties: 'Cooperative Societies',
  paServices: 'PA Services',
  voluntaryWelfareHome: 'Voluntary Welfare Home',
  grassrootUnits: 'Grassroot Units',
  representativeOffice: 'Representative Office',
  newsBureaus: 'News Bureaus',
  xrayLaboratories: 'Xray Laboratories',
  clinicalXrayLaboratories: 'Clinical Xray Laboratories',
  publicAccountingFirms: 'Public Accounting Firms',
  localCompany: 'Local Company',
  bankRepresentativeOffices: 'Bank Representative Offices',
  foreignCompany: 'Foreign Company',
  otherOrganisation: 'Other Organisation',
  foreignLawPracticeRepresentativeOffice:
    'Foreign Law Practice Representative Office',
};

export const kycStatus = {
  KYC_NEW: 'KYC-NEW',
  KYC_PROCESS: 'KYC-PROCESS',
  KYC_APPROVED: 'KYC-APPROVED',
  KYC_REJECTED: 'KYC-REJECTED',
  UND_NEW: 'UND-NEW',
  UND_PROCESS: 'UND-PROCESS',
  UND_SKIPPED_PG: 'UND-SKIPPED-PG',
  UND_APPROVED: 'UND-APPROVED',
  UND_REJECTED_DEFER: 'UND-REJECTED-DEFER',
  UND_REJECTED: 'UND-REJECTED',
  UND_REJECTED_PG: 'UND-REJECTED-PG',
  UND_DOC_PENDING: 'UND-DOC-PENDING',
  OFFER_SENT: 'OFFER-SENT',
  ACTIVATED: 'ACTIVATED',
  UND_LIVE_CHECK_PROCESS: 'UND-LIVE-CHECK-PROCESS',
  UND_LIVE_CHECK_APPROVED: 'UND-LIVE-CHECK-APPROVED',
  UND_LIVE_CHECK_REJECTED: 'UND-LIVE-CHECK-REJECTED',
  UND_LIVE_CHECK_ON_HOLD: 'UND-LIVE-CHECK-ON-HOLD',
  CANCELLED: 'CANCELLED',
  CANCELLED_USER_REQUESTED: 'CANCELLED-USER-REQUESTED',
  CANCELLED_PENDING_DOC: 'CANCELLED-PENDING-DOC',
  CANCELLED_PENDING_ACTIVATION: 'CANCELLED-PENDING-ACTIVATION'
};

export const applicationType = {
  COMPANY: 'COMPANY',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const individualControls = [
  'gender',
  'idNumber',
  'homeAddress',
  'homePostalCode',
  'maritalStatus',
  'nationality',
];
export const companyControls = ['companyIndustryId'];
export const govtCheckStatus = {
  PENDING: 'PENDING',
  PASSED: 'PASSED',
  REJECTED: 'REJECTED',
};


export const transactionType = {
  VCC: 'VCC',
  BNPL: 'BNPL',
  CREDIT_CARD: 'CREDIT-CARD',
};

export const loanType = {
  TERM_LOAN: 'TERM-LOAN',
  CREDIT_LINE: 'CREDIT-LINE'
}

export const maritalStatus = {
  MARRIED: 'Menikah',
  NOT_MARRIED: 'Belum Menikah',
  WIDOW: 'Janda',
  WIDOWER: 'Duda',
  UNKNOWN: 'Tidak Dikenal',
};

export const maritalStatus_EN = {
  MARRIED: 'MARRIED',
  SINGLE: 'SINGLE',
  WIDOWED: 'WIDOWED',
  DIVORCED: 'DIVORCED',
};

export const education = {
  SMP: 'SMP',
  SMA: 'SMA',
  S1: 'S1',
  S2: 'S2',
  S3: 'S3',
  LAINNYA: 'Lainnya',
};

export const genders = [
  { code: 'M', name: 'Male', id: 1 },
  { code: 'F', name: 'Female', id: 2 },
  { code: 'U', name: 'Unknown', id: 99 },
];
export const f2fVerificationApplicability = {
  APPLICABLE: 'APPLICABLE',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};
export const f2fVerificationStatus = {
  DONE: 'DONE',
  NOT_DONE: 'NOT_DONE',
};

export const ID100KFacilityLimit = {
  MAX_BNPL_INDIVIDUAL: 10000000,
  MAX_BNPL_COMPANY: 30000000,
  MAX_VCC_INDIVIDUAL: 100000000,
  MAX_VCC_COMPANY: 100000000,
};
