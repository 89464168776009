<div *ngIf="threadCount != 0" class="pantry">
  <div
    class="modal-container is-open"
    role="dialog"
    aria-describedby="modal-body"
  >
    <div class="modal modal--loading">
      <div class="modal__content">
        <div class="modal__body text-center" id="modal-body">
          <ng-lottie
            [options]="options"
            [styles]="styles"
            width="80px"
            height="80px"
          ></ng-lottie>
          <p>{{ textContent }}</p>
        </div>
      </div>
    </div>
  </div>
</div>