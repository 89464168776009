import { Input, Directive } from '@angular/core';
import { CdkColumnDef } from '@angular/cdk/table';

@Directive({
  selector: '[tableColumn]',
  providers: [{ provide: CdkColumnDef, useExisting: TableColumnDirective }],
})
export class TableColumnDirective extends CdkColumnDef {
  @Input('tableColumn') tableName: string;
}
