import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '@shared/controls/services/loading.service';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'f-loading',
  styleUrls: ['./loading.component.css'],
  templateUrl: './loading.component.html',
})
export class LoadingComponent implements OnDestroy {
  threadCount: number = 0;

  showLoading: Subscription;
  options: AnimationOptions = {
    path: 'https://assets8.lottiefiles.com/packages/lf20_hwjnqw6e.json',
    autoplay: true,
    loop: true,
  };

  styles: Partial<CSSStyleDeclaration> = {
    background: 'transparent',
    margin: '0 auto',
  };

  @Input() textContent: string;
  constructor(private _loadingService: LoadingService) {
    this.showLoading = this._loadingService
      .createThread()
      .subscribe((threadCount: number) => {
        this.threadCount = threadCount;
      });
  }

  ngOnDestroy() {
    this.showLoading && this.showLoading.unsubscribe();
  }
}
