import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

const DEFAULT_GRID = [4, 8];

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormGroupComponent implements OnInit {
  @Input() label: any = null;
  @Input() style = 'vertical'; // horizontal | vertical | flex
  @Input() hasError = false;
  @Input() grid: Array<number> = DEFAULT_GRID;
  @Input() required = false;

  labelGrid;
  controlGrid;

  styles = {
    vertical: 'vertical',
    horizontal: 'horizontal',
    flex: 'flex',
  };

  constructor() {}

  ngOnInit() {
    let [labelGrid, controlGrid] = this.grid;
    if (!this.label) {
      controlGrid = 12;
    }

    switch (this.style) {
      case this.styles.vertical:
      case this.styles.flex:
        this.labelGrid = `col-sm-${labelGrid}`;
        this.controlGrid = `col-sm-${controlGrid}`;
        break;
      case this.styles.horizontal:
        this.labelGrid = `col-sm-12`;
        this.controlGrid = `col-sm-12`;
        break;
      default:
        break;
    }
  }
}
