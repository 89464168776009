import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormGroup } from '@angular/forms';
import CONFIG from '../configuration/configuration';
import CONFIGURATION from '../configuration/configuration';
import { environment } from '../environments/environment';
import _ from 'lodash';
import { isSGUEN } from '../shared/uen.util';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private decimalPipe: DecimalPipe) {

  }

  formatDecimal(value: number): string {
    return this.decimalPipe.transform(value ? value : 0, CONFIG.pipe.precision, CONFIG.number_format.locale);
  }


  truncateDecimal(value: number, precision: number) {
    let denominator;
    let convertedValue;
    let result;
    if (precision !== 0) {
      const checkNegative = (String(value).startsWith('-'));
      const absoluteValue = Math.abs(value);
      if (absoluteValue < 1000000) {
        denominator = 1000;
        convertedValue = absoluteValue / denominator;
      } else if (absoluteValue < 1000000000) {
        denominator = 1000000;
        convertedValue = absoluteValue / denominator;
      } else if (absoluteValue < 1000000000000) {
        denominator = 1000000000;
        convertedValue = absoluteValue / denominator;
      } else if (absoluteValue < 1000000000000000) {
        denominator = 1000000000000;
      }
      convertedValue = absoluteValue / denominator;
      convertedValue = (Math.ceil(convertedValue * denominator) / denominator).toFixed(2);
      if (absoluteValue < 1000000) {
        result = this.formatDecimal(absoluteValue);
      } else if (absoluteValue >= 1000000 && absoluteValue < 1000000000) {
        result = this.formatDecimal(convertedValue) + ' million';
      } else if (absoluteValue > 1000000000 && absoluteValue < 1000000000000) {
        result = this.formatDecimal(convertedValue) + ' billion';
      } else if (absoluteValue > 1000000000000 && absoluteValue < 1000000000000000) {
        result = this.formatDecimal(convertedValue) + ' trillion';
      } else {
        result = value.toFixed(2);
      }
      if (checkNegative) {
        result = '(' + result + ')';
      }
    } else {
      result = value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    return result;
  }

  disableFields(form: FormGroup, fields): void {
    fields.forEach(field => {
      if (form.controls.hasOwnProperty(field)) {
        form.controls[field].disable();
      } else {
        console.warn(`Unable to find ${field} in form to be disabled`);
      }
    });
  }

  removeDelimiter(text: string): string {
    let thousandsSeparatorRegExp = new RegExp('\\' + CONFIGURATION.number_format.thousands, 'g');
    let decimalSeparatorRegExp = new RegExp('\\' + CONFIGURATION.number_format.decimal, 'g');

    text += '';
    text = text.replace(thousandsSeparatorRegExp, '');
    text = text.replace(decimalSeparatorRegExp, '.');

    return text;
  }
  /**
   * This function will only flatten the first level
   * of an object instead of recursively flatten an object.
   */
  flatten(nestedObject): any {
    let flattenObject = {};
    Object.keys(nestedObject).forEach(key => {
      if (nestedObject[key] && typeof nestedObject[key] === 'object') {
        Object.keys(nestedObject[key]).forEach(innerKey => {
          flattenObject[`${key}_${innerKey}`] = nestedObject[key][innerKey];
        });
      } else {
        flattenObject[key] = nestedObject[key]
      }
    })
    return flattenObject
  }

  // For changing the base url for downloading the document (ID Command center)
  changeBaseUrl(url: string) {
    if (environment.Base_api_url.includes('modalku')) {
      const strippedUrl = new URL(url);
      return environment.Base_api_url.concat(strippedUrl.pathname, strippedUrl.search);
    }
  }

  isIndonesiaHost() {
    return environment.Base_api_url.includes('modalku');
  }

  // For date with empty value in native type input
  parseDateValue(value: Date) {
    if (value) {
      let date = new Date(value);
      return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    } else {
      return '';
    }
  }

  // Return FallBack in case value is undefined
  returnFallbackValue(value, fallbackValue) {
    if (_.isUndefined(value)) {
      return fallbackValue;
    }
    return value;
  }

  getProductCodeFromIdNumber(idNumber: string | number) {
    if (isSGUEN(idNumber)) {
      return 'BRC';
    }
    return 'BRIG';
  }
}
