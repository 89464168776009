import { Route } from '@angular/router';

export const financeRoute: Route[] = [
  {
    path: 'finance/batch-upload',
    loadChildren: () =>
      import('../modules/finance/component/batch-upload/batch-upload.module').then(
        (m) => m.BatchUploadModule
      ),
    data: { title: 'Batch Upload' },
  },
  {
    path: 'finance/dda',
    loadChildren: () =>
      import('../modules/finance/component/dda/dda.module').then((m) => m.DDAModule),
  },
  {
    path: 'finance/instalments',
    loadChildren: () =>
      import('../modules/finance/component/installment/installment.module').then(
        (m) => m.InstallmentModule
      ),
  },
  {
    path: 'finance/instalment',
    loadChildren: () =>
      import('../modules/finance/component/installment/installment.module').then(
        (m) => m.InstallmentModule
      ),
  },
  {
    path: 'finance/transfer',
    data: { title: 'Transfer' },
    loadChildren: () =>
      import('../modules/finance/component/transfer/transfer.module').then((m) => m.TransferModule),
  },
  {
    path: 'finance/disbursals',
    data: { title: 'Disbursals' },
    loadChildren: () =>
      import('../modules/finance/component/disbursal/disbursal.module').then(
        (m) => m.DisbursalModule
      ),
  },
  {
    path: 'finance/bank',
    data: { title: 'Bank' },
    loadChildren: () =>
      import('../modules/finance/component/bank/bank.module').then((m) => m.BankModule),
  },
  {
    path: 'finance',
    loadChildren: () =>
      import('../modules/finance/component/transfer-list-page/transfer-list.module').then(
        (m) => m.TransferListModule
      ),
  },
];
