import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ErrorLog } from './error.model';

export interface ErrorState extends EntityState<ErrorLog> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'error' })
export class ErrorStore extends EntityStore<ErrorState> {

  constructor() {
    super();
  }

}
