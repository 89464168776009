import CONFIGURATION from '@shared-configuration/configuration';
import { Observable } from 'rxjs';
import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Modal } from '@shared-components/modal';

@Component({
  selector: 'app-modal-update-ctos-info',
  templateUrl: './modal-update-ctos-info.component.html',
  styleUrls: ['./modal-update-ctos-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ModalUpdateCTOSInfoComponent implements OnInit {
  @Output() onOk = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @ViewChild('updateCTOSInfoModal', { static: true }) updateCTOSInfoModal: Modal;
  featureFlagMapping: any;
  enableCtos: Boolean;
  constructor(
  ) {}

  ngOnInit() {
    this.enableCtos = CONFIGURATION.enable_ctos;
  }

  open() {
    if (this.enableCtos){
      this.updateCTOSInfoModal.open();
    }
  }

  close() {
    this.updateCTOSInfoModal.close();
  }

  onRetrieveCTOS() {
    this.onOk.emit(true);
  }
}
