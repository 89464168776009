<div *ngIf="data">
  <div class="row">
    <div class="col-lg-12 table-responsive">
      <table class="table table-striped" [ngClass]="customClass">
        <thead>
          <tr>
            <th *ngIf="hasCheckBox">
                <input class="form-check-input" type="checkbox" [checked]="selectAll" (click)="onToggleSelectAll()">
            </th>
            <ng-container *ngFor="let column of columns">
                <th *ngIf="column.show">
                    {{column.header}}
                    <i *ngIf="showSort() && column.sort"
                       class="pull-right sort-toggle fa "
                       (click)="invokeSort(column.column)"
                       [ngClass]="{ 'fa-sort-alpha-asc' : sortColumn[column.column].asc, 'fa-sort-alpha-desc' : !sortColumn[column.column].asc, 'sort-active' : sortColumn[column.column].active }">
                    </i>
                  </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr class="table-row" *ngFor="let row of data | paginate: {
                                id: 'table-pagination',
                                itemsPerPage: query.recordsPerPage,
                                currentPage: query.page,
                                totalItems: total
                            }" (click)="invokeOnClick(row)">
            <td (click)="$event.stopPropagation()" *ngIf="hasCheckBox">
                <input class="form-check-input" type="checkbox" [checked]="row?.selected" *ngIf="customCondition(checkBoxCondition, row)" (click)="onCheckBoxClick(row)">
            </td>
            <ng-container *ngFor="let column of columns">
                <td *ngIf="column.show" (click)="invokeOnColumnClick(column, row)" [ngStyle]="column.styles" [ngClass]="column.class" title="{{ getRowValue(row, column) | dynamic: column.pipe }}">
                    {{ getRowValue(row, column) | dynamic: column.pipe }}
                    <div *ngFor="let button of column.buttons" (click)="$event.stopPropagation()">
                      <button type="button"
                              [ngClass]="button.class"
                              [innerHtml]="button.content"
                              (click)="buttonClick(button, row)"
                              title="{{button.title}}"
                              *ngIf="customCondition(button.condition, row)">
                            </button>
                    </div>
                  </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 text-left">
      <select  *ngIf="showSize()" (change)="invokeSizeChange($event.target.value)" [(ngModel)]="query.recordsPerPage">
        <option value="10" selected>10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="1000">1000</option>
      </select>
    </div>
    <div class="col-lg-9 text-right">
      <div class="pagination">
        <pagination-controls
          *ngIf="showPaging()"
          id="table-pagination"
          previousLabel=""
          nextLabel=""
          autoHide="true"
          (pageChange)="invokePageChange($event)"
        >
        </pagination-controls>
      </div>
    </div>
  </div>
</div>
