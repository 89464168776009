import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';

export const HelperComponent = [
    TableModule,
    AccordionModule,
    ToastModule,
    CalendarModule,
];

