<div class="radio">
  <input
    type="radio"
    [id]="id"
    [name]="name"
    [value]="data"
    [checked]="checked"
    (change)="onChange($event)"
  />
  <label class="control-label" [for]="id">{{ label }}</label>
</div>
