import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
})

export class NotFoundPageComponent implements OnInit {
  currentUrl: string;
  currentRole: string;
  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.currentRole = localStorage.getItem('currentRole');
    this.currentUrl = '';
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.currentUrl = params['url'];
    });
  }
}
