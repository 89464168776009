import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

@Injectable()
export class LoadingService {
  items: { [threadName: string]: number } = { global: 0 };

  threadSub: { [threadName: string]: Subject<number> } = {};

  threadByName = (name: string) =>
    (this.threadSub[name] && this.threadSub[name].asObservable()) || of();

  authenticationSubject: Subject<boolean>;

  constructor() {
    this.authenticationSubject = new Subject<boolean>();
  }

  // use createThread with name to scope your loading queue
  createThread(name = 'global') {
    if (!this.threadSub.hasOwnProperty(name)) {
      this.threadSub[name] = new Subject<number>();
    }
    return this.threadByName(name);
  }

  addProcess(name = 'global') {
    this.items[name] = this.items[name] || 0;
    this.items[name]++;
    this.createThread(name);
    this.threadSub[name].next(this.items[name]);
  }

  removeProcess(name = 'global') {
    if (!this.threadSub.hasOwnProperty(name)) return;
    if (this.items[name] && this.items[name] > 0) {
      this.items[name]--;
    } else {
      this.items[name] = 0;
    }
    this.threadSub[name].next(this.items[name]);
  }

  setAuthenticationStatus(status: boolean): void {
    this.authenticationSubject.next(status);
  }

  getAuthentication(): Observable<boolean> {
    return this.authenticationSubject.asObservable();
  }
}