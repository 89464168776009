import { ActivatedRouteSnapshot } from '@angular/router';

function urlSegmentsToUrl(route: ActivatedRouteSnapshot): string {
  return route.url
    .map(segment => segment.toString())
    .join('/');
}

function getLastRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  let lastRoute = route;
  if (lastRoute.parent === null) {
    // Top down traversal to get last node in tree.
    while (lastRoute.firstChild !== null) {
      lastRoute = lastRoute.firstChild;
    }
  }
  return lastRoute;
}

/* Output /path/to/route/123/tab- */
function getResolvedUrl(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
    .map(urlSegmentsToUrl)
    .join('/');
}

/* Output /path/to/route/:id/tab- */
function getConfiguredUrl(route: ActivatedRouteSnapshot): string {
  const targetRoute = getLastRoute(route);
  return targetRoute.pathFromRoot
    .map(activeRoute => {
      if (activeRoute.routeConfig) {
        if (activeRoute.routeConfig.path) {
          return activeRoute.routeConfig.path
        }
        // For segment matcher config, resolve to actual value.
        if (activeRoute.routeConfig.matcher) {
          return urlSegmentsToUrl(activeRoute);
        }
      }
      return null;
    })
    .join('/');
}

export {
  getResolvedUrl,
  getConfiguredUrl
}
