import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, PercentPipe, TitleCasePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import id from '@angular/common/locales/id';
import enMy from '@angular/common/locales/en-MY';
import enSG from '@angular/common/locales/en-SG';

registerLocaleData(id);
registerLocaleData(enMy);
registerLocaleData(enSG);

@NgModule({
    imports: [
        CommonModule
    ],
    providers:[
        CurrencyPipe,
        DatePipe,
        PercentPipe,
        TitleCasePipe        
    ]
})
export class PipesModule { }
