import { NgModule } from "@angular/core";
import { SafeTransform } from "./safe-transform.pipe";

@NgModule({
    declarations:[
        SafeTransform    
    ],
    exports:[
        SafeTransform
    ],
})

export class SafeTransformModule {}