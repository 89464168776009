import { throwError as observableThrowError, Observable, tap, catchError, finalize } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { LoadingService } from '../controls/services/loading.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    private loadingService: LoadingService,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loadingService.addProcess();
    this.loadingService.setAuthenticationStatus(true);
    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          this.loadingService.removeProcess();
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.loadingService.removeProcess();
        }
        return observableThrowError(err);
      }),
      finalize(() => this.loadingService.removeProcess())
    );
  }
}
