import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermissionService } from './permission.service';
import { getConfiguredUrl } from './route-util';
import { matchScreens, trimSlash } from './util';

const PREFIX = '@';

/**
 * Add the template content to the DOM:
 * Role has screen
 * User pass the rules
 *
 * Empty binding value will match with activated route.
 * <component *permission>
 *  {{ content }}
 * </component>
 *
 * Binding value must prefix with '@', will match against screens in order below.
 * '@component' indicates full access on the component.
 * '/path/to/route@component' indicates partial access on the specified route.
 * <component *permission="'@component'">
 *  {{ content }}
 * </component>
 */
@Directive({
  selector: '[permission]'
})
export class PermissionDirective implements OnInit, OnDestroy {
  private hasView: boolean = false;

  private subscription = Subscription.EMPTY;

  @Input()
  set permission(value: string) {
    this.screen = this.validateValue(value);
    this.updateView();
  }
  private screen: string;

  get route() {
    return `${trimSlash(this._route)}${this.screen}`;
  }
  private _route: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService,
    route: ActivatedRoute
  ) {
    this._route = getConfiguredUrl(route.snapshot);
  }

  private validateValue(value: string) {
    const trimmedValue = value.trim();
    if (trimmedValue.length > 0 && trimmedValue[0] !== PREFIX) {
      throw new Error(`PermissionDirective: Invalid property value "${value}". Value should prefix with ${PREFIX}.`);
    }
    return trimmedValue;
  }

  private updateView() {
    const userScreens = this.permissionService.getScreenAccess();
    const hasAccess = this.screen && matchScreens(this.screen, userScreens) || matchScreens(this.route, userScreens);
    if (hasAccess && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!hasAccess && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.permissionService.screens$.subscribe(
        this.updateView.bind(this)
      )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
