import { environment as staging } from './environment.staging';
import { environment as stagingID } from './environment.staging-id';
import { environment as uat } from './environment.uat';
import { environment as uatID } from './environment.uat-id';
import { environment as uatMSM } from './environment.uat-msm';
import { environment as production } from './environment.production';
import { environment as productionID } from './environment.production-id';
import { environment as productionMSM } from './environment.production-msm';

let env;

const prodBaseURLS = [
  {
    host: [
      'webold.modalku.co.id',
      'www.webold.modalku.co.id',
      'commandcenter.modalku.co.id',
      'www.commandcenter.modalku.co.id',
    ],
    envFile: 'production-id',
  },
  {
    host: [
      'webold.fsmkcommand.center',
      'www.webold.fsmkcommand.center',
      'fsmkcommand.center',
      'www.fsmkcommand.center',
    ],
    envFile: 'production',
  },
  { host: ['commandcenter.elevateforbusiness.id'], envFile: 'production-msm' },
  { host: ['web-uat.fsmkcommand.center'], envFile: 'uat' },
  { host: ['commandcenter-uat.elevateforbusiness.id'], envFile: 'uat-msm' },
  { host: ['web-uat-commandcenter.modalku.co.id'], envFile: 'uat-id' },
  { host: ['web-staging.fsmkcommand.center'], envFile: 'staging' },
  { host: ['web-staging-commandcenter.modalku.co.id'], envFile: 'staging-id' },
];

const prodEnv = prodBaseURLS.find((prod) => prod.host.indexOf(window.location.host) != -1);

if (prodEnv) {
  env = prodEnv.envFile;
} else {
  env = window.localStorage.getItem('environment');
}

let envValues;

switch (env) {
  case 'staging':
    envValues = staging;
    break;
  case 'staging-id':
    envValues = stagingID;
    break;
  case 'uat':
    envValues = uat;
    break;
  case 'uat-id':
    envValues = uatID;
    break;
  case 'uat-msm':
    envValues = uatMSM;
    break;
  case 'production':
    envValues = production;
    break;
  case 'production-id':
    envValues = productionID;
    break;
  case 'production-msm':
    envValues = productionMSM;
    break;
  default:
    envValues = uat;
}

export const environment = {
  ...envValues,
  selectedEnvironment: env,
};
