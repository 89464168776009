import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Modal } from '../modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmationModalComponent {
    @ViewChild('confirmationModal', { static: true }) confirmationModal: Modal;
    public confirmation: any = {
        title: '',
        message: '',
        onYes: null,
        onNo: null
    };

    constructor(private cdnRef: ChangeDetectorRef){}

    open() {
        this.confirmationModal.open();
    }
    close() {
        this.confirmationModal.close();
    }
    set(title, message, actionOnYes, actionOnNo) {
        this.confirmation = {
            title,
            message,
            onYes: actionOnYes,
            onNo: actionOnNo,
        };
        this.cdnRef.markForCheck();
    }
    setAndOpen(title, message, actionOnYes, actionOnNo) {
        this.set(title, message, actionOnYes, actionOnNo);
        this.open();
    }
}
