import {
  throwError as observableThrowError,
  Observable,
  catchError,
  refCount,
  publishLast,
  map,
} from 'rxjs';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IDocTemplateRef } from '../models/loan.class';
import { environment } from '../environments/environment';

export enum ContentType {
  json = 'application/json',
  xml = 'application/xml',
  pdf = 'application/pdf',
  html = 'text/html',
}

type ObjectType = { [key: string]: any };
const LOAN_URL = environment.Loan_EndPoint;

@Injectable()
export class ServiceBase {
  constructor(protected http: HttpClient) {}

  private parseErrorBlob(response): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = () => {
        observer.error(this.resolveErrorMessage(reader.result.toString()));
        observer.complete();
      };
    });
    reader.readAsText(response);
    return obs;
  }

  private resolveErrorMessage(error: string): string {
    try {
      const body = JSON.parse(error);
      if (_.has(body, 'message')) {
        return body.message;
      } else if (_.has(body, 'error') && _.has(body.error, 'message')) {
        return body.error.message;
      }
      return body;
    } catch (e) {
      return error || `Please contact support to resolve. Unexpected error: ${e}`;
    }
  }

  // Angular Response not injectable,
  // no choice but to add xml parse here.
  protected parseXML(text: string) {
    const domParser = new DOMParser();
    try {
      return domParser.parseFromString(text, ContentType.xml);
    } catch (e) {
      console.log(e);
      return '';
    }
  }

  protected stringifyQueryString(params) {
    const queries = [];
    for (const key in params) {
      const val = params[key];
      if (Array.isArray(val)) {
        queries.push(key + '=' + val.join(','));
      } else {
        queries.push(`${key}=${val}`);
      }
    }
    return queries.join('&');
  }

  protected setRequestOptions() {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Accept', 'application/json');
    httpHeaders = httpHeaders.append('Content-Type', 'application/json');
    return {
      headers: httpHeaders,
      observe: 'response' as 'body',
    };
  }

  protected setHttpParams(objects, concatenateArray = true): any {
    let httpParams;
    if (objects) {
      Object.keys(objects).forEach((k) => {
        (objects[k] == null || (Array.isArray(objects[k]) && objects[k].length === 0)) &&
          delete objects[k]; // Remove empty attributes and empty array
        if (Array.isArray(objects[k]) && concatenateArray) {
          objects[k] = objects[k].join(',');
        }
      });
      httpParams = new HttpParams({ fromObject: objects });
    }
    return { params: httpParams };
  }

  protected postHeaderRequest() {
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');
    return {
      headers: httpHeaders,
      observe: 'response' as 'body',
    };
  }

  protected vendorAuthOptions() {
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('vendor', 'mokapos');
    return {
      headers: httpHeaders,
      observe: 'response' as 'body',
    };
  }

  mapResponse(response: any): any {
    let result: any;
    try {
      result = response.body;
    } catch (e) {
      throw new Error(response.toString());
    }
    return result;
  }

  mapResponseWithProperty(response: any, property: any): any {
    let result: any;
    try {
      result = response.body[property];
    } catch (e) {
      throw new Error(response.toString());
    }
    return result;
  }

  protected postHeaderRequestUpload(header = true, obj?) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');
    return {
      headers: httpHeaders,
      params: obj,
    };
  }

  protected errorHandler(err: any): Observable<any> {
    if (err._body instanceof Blob || err instanceof Blob) {
      return this.parseErrorBlob(err);
    }

    if (typeof err === 'string' || err instanceof String) {
      return observableThrowError(err);
    }

    if (err.status === 0 && err.ok === false) {
      // This error usually happens when the browser blocks the code to access the response due to Same Origin Policy.
      // For example, when Cloudflare returns an 504 timeout error without CORS header.
      return observableThrowError({
        status: err.status,
        _body: JSON.stringify({
          code: err.status,
          message: 'Unknown error',
        }),
      });
    }
    return observableThrowError(
      this.resolveErrorMessage(_.has(err.error, 'message') ? this.getErrorMessage(err.error.message) : err.error)
    );
  }

  protected getErrorMessage(err: any): string {
    if (typeof err === 'object' ) {
      const keys = Object.keys(err);
      return keys.map(key => `${key}: ${err[key]}`).join('\n');
    } else {
      return err;
    }
  }

  protected responseFileCheck(res) {
    try {
      const body = res;
    } catch (err) {
      // If it can not decode as JSON, It Ok to pass through
      return res;
    }

    return this.responseCheck(res);
  }

  protected responseCheck(res) {
    if ((res.status <= 400 || res.status === 500) && res.text !== '') {
      try {
        const body = res;
        if ((_.has(body, 'code') && _.has(body, 'message')) || _.has(body, 'error')) {
          throw body.error.message;
        } else if (body.message && _.has(body.message, 'error')) {
          throw body.message;
        } else {
          return res.body;
        }
      } catch {
        return res.body;
      }
    } else if (res.status === 504) {
      // API gateway timeout response.
      throw observableThrowError({
        status: false,
        error: {
          code: 504,
          message: 'API gateway timeout.',
        },
      });
    } else {
      return res;
    }
  }

  protected putRequest(endPoint, body): Observable<any> {
    return this.http
      .put(endPoint, body, this.postHeaderRequest())
      .pipe(catchError(this.errorHandler.bind(this)), publishLast(), refCount());
  }

  protected postRequest(endPoint, body): Observable<any> {
    return this.http
      .post(endPoint, body, this.postHeaderRequest())
      .pipe(catchError(this.errorHandler.bind(this)), publishLast(), refCount());
  }

  protected getRequest(endPoint): Observable<any> {
    return this.http
      .get(endPoint, this.setRequestOptions())
      .pipe(catchError(this.errorHandler.bind(this)), publishLast(), refCount());
  }

  protected deleteRequest(endPoint): Observable<any> {
    return this.http
      .delete(endPoint, this.postHeaderRequest())
      .pipe(catchError(this.errorHandler.bind(this)), publishLast(), refCount());
  }

  addRoleAccess(
    productId: number,
    templateId: number,
    roles: string[]
  ): Observable<IDocTemplateRef> {
    return this.http
      .post(`${LOAN_URL}/v2/products/${productId}/doc_template_refs/${templateId}/oso/roles`, {
        roles,
      })
      .pipe(
        map((res) => this.responseCheck(res)),
        map((res) => res.data),
        catchError((error) => this.errorHandler(error))
      );
  }

  deleteRoleAccess(
    productId: number,
    templateId: number,
    role: string
  ): Observable<IDocTemplateRef> {
    return this.http
      .delete(
        `${LOAN_URL}/v2/products/${productId}/doc_template_refs/${templateId}/oso/roles/${role}`,
        this.postHeaderRequest()
      )
      .pipe(
        map((res) => this.responseCheck(res)),
        map((res) => res.data),
        catchError((error) => this.errorHandler(error))
      );
  }
}
