import { NgModule, ModuleWithProviders } from '@angular/core';
import { PermissionDirective } from './permission.directive';
import { PermissionService } from './permission.service';
import { PermissionGuard } from './permission.guard';

@NgModule({
  declarations: [
    PermissionDirective
  ],
  exports: [
    PermissionDirective
  ]
})
export class PermissionModule {
  static forRoot(): ModuleWithProviders<PermissionModule> {
    return {
      ngModule: PermissionModule,
      providers: [
        PermissionService,
        PermissionGuard
      ]
    }
  }

  static forChild(): ModuleWithProviders<PermissionModule> {
    return {
      ngModule: PermissionModule,
      providers: [
        PermissionService,
        PermissionGuard
      ]
    }
  }
}
