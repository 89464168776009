<div class="row border-bottom">
  <nav class="navbar navbar-static-top white-bg" role="navigation" style="margin-bottom: 0">
    <div class="navbar-header">
      <app-minimalizasidebar></app-minimalizasidebar>
    </div>
    <div>
    </div>
    <ul class="nav navbar-top-links navbar-right pull-right">
      <li *ngIf="showLanguageSwitcher" style="border: 1px solid #d6d6d6 !important;border-radius:5px;">
        <img src="../../../assets/images/world-icon.svg" style="margin-left:5px;" />
        <p-dropdown [options]="languageList" [(ngModel)]="selectedLanguage" (onChange)="onLanguageChange()">
        </p-dropdown>
      </li>
      <li>
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" data-toggle="dropdown" href>
            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
          </button>
          <ul class="dropdown-menu animated m-t-xs">
            <li *ngFor=" let ccApp of ccApps; ">
              <a [href]="ccApp.url" target="_blank">{{ccApp.name}}</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="environment-configurations" *ngIf="isTestingEnvironment">
          <ng-container *ngIf="isTestingEnvironment">
            <select id="environmentSelection" (change)="onEnvironmentSelectionChange($event)">
              <option *ngFor="let environment of environments" [value]="environment" [selected]="currentEnvironment === environment">
                {{ environment }}</option>
            </select>
          </ng-container>
        </div>
      </li>
      <li title="Logout">
        <a (click)="onLogout($event)">
          <i class="fa fa-sign-out"></i>
        </a>
      </li>
    </ul>
  </nav>
</div>
