<ng-container *ngIf="isModal">
  <div class="content-preview">
    <modal #pdfModal [closeOnOutsideClick]="false" (onClose)="handleClose()">
      <modal-header>
        <h3>PDF Viewer</h3>
      </modal-header>
      <modal-content>
        <div *ngIf="visible">
          <iframe id="ifDoc" src="" width="100%" height="645px"></iframe>
        </div>
      </modal-content>
      <modal-footer>
        <button class="btn btn-primary" (click)="handleCloseClick()">
          Close
        </button>
      </modal-footer>
    </modal>
  </div>
</ng-container>

<ng-container *ngIf="!isModal">
  <div class="content-preview" *ngIf="getViewableFileType(getDocumentType(fileName)); else nonSupportedDocType">
    <iframe id="ifDoc" src="" width="100%"></iframe>
  </div>
  <ng-template #nonSupportedDocType>      
      <br>
      <p class="infoBox"> Viewer does not support <strong>.{{getDocumentType(fileName)}}</strong> documents</p>
  </ng-template>
</ng-container>
