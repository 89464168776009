/**
 * Validates UEN of businesses in Singapore
 * https://www.uen.gov.sg/ueninternet/faces/pages/admin/aboutUEN.jspx
 */
export function isSGUEN(uen) {
  const isUENBusinessRegisteredWithACRA = () => {
    // 8 numbers and 1 uppercase alpahbet
    // Match to '12345678A'
    return RegExp('^[0-9]{8}[A-Z]$').test(uen);
  };

  const isUENLocalCompanyRegisteredWithACRA = () => {
    // 9 numbers and 1 uppercase alpahbet
    // Match to '123456789A'
    return RegExp('^[0-9]{9}[A-Z]$').test(uen);
  };

  const isUENNewEntity = () => {
    const centuryIndicator = ['T', 'S', 'R'];
    // prettier-ignore
    const entityTypeIndicator = [
      'LP', 'LL', 'FC', 'PF', 'RF', 'MQ', 'MM', 'NB', 'CC', 'CS', 'MB', 'FM', 'GS', 'GA',
      'GB', 'DP', 'CP', 'NR', 'CM', 'CD', 'MD', 'HS', 'VH', 'CH', 'MH', 'CL', 'XL', 'CX',
      'RP', 'TU', 'TC', 'FB', 'FN', 'PA', 'PB', 'SS', 'MC', 'SM'
    ];
    // Either T, S, R
    // 2 numbers
    // Static entity type indicators (2 uppercase alphabets)
    // 4 numbers
    // 1 uppercase alphabet
    // Match to 'T12LP1234A'
    return RegExp(
      `^[${centuryIndicator.join()}][0-9]{2}(?:${entityTypeIndicator.join(
        '|'
      )})[0-9]{4}[A-Z]$`
    ).test(uen);
  };

  return (
    isUENBusinessRegisteredWithACRA() ||
    isUENLocalCompanyRegisteredWithACRA() ||
    isUENNewEntity()
  );
}

export function isSGIC(ic) {
  const typeIndicator = ['S', 'T', 'F', 'G'];
  // Either S, T, F, G
  // 7 numbers
  // 1 uppercase alphabet
  // Match to 'S1234567A'
  return RegExp(`^[${typeIndicator.join()}][0-9]{7}[A-Z]$`).test(ic);
}
