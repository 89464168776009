import { NgModule } from "@angular/core";
import { Modal, ModalHeader, ModalContent, ModalFooter } from "./modal";
import { CommonModule } from '@angular/common';

export { Modal, ModalContent, ModalFooter, ModalHeader } from "./modal";

@NgModule({
    imports: [CommonModule],
    declarations: [
        Modal,
        ModalHeader,
        ModalContent,
        ModalFooter,
    ],
    exports: [
        Modal,
        ModalHeader,
        ModalContent,
        ModalFooter,
    ],
})
export class ModalModule {

}