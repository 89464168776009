import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from '@shared-environments/environment';
import { hmrBootstrap } from './hmr';
import { AppModule } from './app/app.module';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-057097}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Funding_Societies_Pte_Ltd}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{23_May_2025}____[v3]_[01]_MTc0Nzk1NDgwMDAwMA==ee146bcf4183a08c4302d1c9e75a2482'
);

function stripHash(url: URL): string {
  const nextUrl = url;
  nextUrl.hash = '';
  return nextUrl.toString();
}

const bootstrap = () =>
  platformBrowserDynamic().bootstrapModule(AppModule, {
    // TODO: remove preserveWhitespaces and fix breaking layouts.
    preserveWhitespaces: true,
  });

function main() {
  const url = new URL(window.location.href);
  const hash = url.hash.substring(1) || '';
  if (hash.startsWith('access_token=')) {
    const bearerToken = hash.split('access_token=')[1];
    localStorage.setItem('oauthToken', bearerToken);
    window.location.assign(stripHash(url));
    return;
  }
  // tslint:disable-next-line: no-string-literal
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    if (environment.production) {
      enableProdMode();
      const scriptEl = window.document.createElement('script');
      scriptEl.src = 'assets/js/fullstory.js';
      window.document.head.appendChild(scriptEl);
    }
    bootstrap().catch((err) => console.log(err));
  }
}

main();
