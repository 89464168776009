import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { NavigationService } from '../../services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { ReviewAppService } from '../../services/review-app.service';
import { DynamicProductionEnvironmentService } from './../../services/dynamic-production-environment.service';
import CONFIGURATION, { isTestingEnvironment } from '../../configuration/configuration';

@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.css'],
  providers: [NavigationService, FeatureFlagService]
})

export class TopnavbarComponent implements OnInit {
  languageList: any;
  selectedLanguage: any;
  showLanguageSwitcher: boolean;
  currentEnvironment: string;
  environments: Array<Object>;
  isTestingEnvironment: boolean;
  showEnvironmentSwitcher: boolean;
  showVpnSwitcher: boolean;
  featureFlagObservable: Observable<any>;
  ccApps = [
    {
      'name': 'Workflow',
      'url': '/workflow'
    },
    {
      'name': 'Command Center',
      'url': '/'
    }
  ];

  constructor(private navigationService: NavigationService,
    private translateService: TranslateService,
    private reviewAppService: ReviewAppService,
    private featureFlagService: FeatureFlagService,
    private dynamicProductionEnvironmentService: DynamicProductionEnvironmentService
  ) {
    this.isTestingEnvironment = isTestingEnvironment();
    this.showEnvironmentSwitcher = environment.name.toLowerCase() === 'live';
  }

  ngOnInit() {
    const { showVpnSwitcher } = this.featureFlagService.getFeatureFlagKeys();
    this.featureFlagObservable = this.featureFlagService.getFeatureFlagObservable();
    this.featureFlagObservable.subscribe( (flags) => {
      this.showVpnSwitcher = flags[showVpnSwitcher];
    });
    if (this.isTestingEnvironment) {
      this.environments = this.reviewAppService.getEnvironments();
      this.currentEnvironment = this.reviewAppService.getEnvironment();
      localStorage.setItem('environment', this.currentEnvironment);
      if (!this.reviewAppService.getEnvironments()) {
        this.reviewAppService.changeEnvironment(this.reviewAppService.defaultEnvironment);
      }
    }
    if (this.showEnvironmentSwitcher) {
      this.environments = this.dynamicProductionEnvironmentService.getEnvironments();
      this.currentEnvironment = this.dynamicProductionEnvironmentService.getEnvironment();
      if (!this.currentEnvironment) {
        this.dynamicProductionEnvironmentService.changeEnvironment(this.dynamicProductionEnvironmentService.defaultEnvironment);
      }
    }
    this.showLanguageSwitcher = CONFIGURATION.language.show;
    this.languageList = [
      { label: 'English', value: { name: 'English', code: 'en' } },
      { label: 'Bahasa', value: { name: 'Bahasa', code: 'id' } },
    ];
  }

  onEnvironmentSelectionChange(e) {
    this.reviewAppService.changeEnvironment(e.srcElement.selectedOptions[0].value);
    window.location.reload();
  }

  onVpnEnvironmentSelectionChange(e) {
    this.dynamicProductionEnvironmentService.changeEnvironment(e.srcElement.selectedOptions[0].value);
    window.location.reload();
  }

  onLanguageChange() {
    this.translateService.setDefaultLang(this.selectedLanguage.code);
  }

  onLogout(e) {
    this
      .navigationService.logout()
      .subscribe(
        res => {
          this.featureFlagService.closeLdClient();
          localStorage.removeItem('oauthToken');
          window.location.href = environment.Logout_Page_URL + window.location.origin;
        }
      );;
  }
}
