import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../controls/services/loading.service';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.css'],
})

export class LayoutsComponent implements OnInit {
  authenticated: boolean;

  constructor(
    private loadingService: LoadingService,
    private router: Router
  ) {
    this.authenticated = false;
  }

  ngOnInit() {
    this.loadingService
      .getAuthentication()
      .subscribe(_ => {
        this.authenticated = true;
      });
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingService.addProcess();
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loadingService.removeProcess();
      }
    });
  }
}
