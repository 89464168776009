<div class="container full-width">
  <div class="fs-logo"
  [ngClass]="isIndonesiaHost ? 'mk-logo' : 'fs-logo' ">
  </div>
    <div class="d-flex">
      <div class="">
        <h2><b>{{ 'maintenance_page.title' | translate }}</b></h2>
        <p>{{ 'maintenance_page.description' | translate }}</p>
      </div>
    </div>
</div>