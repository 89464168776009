import { NgModule } from "@angular/core";
import { DateTimeFormatPipe } from "./date-time-format.pipe";
import { DatePipe } from "@angular/common";

@NgModule({
    declarations:[
        DateTimeFormatPipe
    ],
    providers:[
        DateTimeFormatPipe,
        DatePipe
    ],
    exports: [
        DateTimeFormatPipe
    ]
})

export class DateTimeFormatPipeModule {
    
}