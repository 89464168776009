import { catchError, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { ServiceBase } from './service-base.service';
import { User } from '../models/user.class';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StaffService extends ServiceBase {
  user: any;
  endPoint = environment.Staff_Endpoint;

  constructor(http: HttpClient) {
    super(http);
  }

  // users
  async getCurrentUser(): Promise<User> {
    if (!this.user) {
      this.user = await this.http
        .get(`${this.endPoint}/users`).toPromise();
    }
    return this.user;
  }

  getUsers(keyword = '') {
    const URL = `${this.endPoint}/users/search?key=${keyword}`;
    return this.http.get(URL, this.setRequestOptions()).pipe(
      map(res => this.responseCheck(res))).toPromise();
  }

  createUser(email: string, name: string, role: string) {
    return this.http
      .post(`${this.endPoint}/users`, {
        email: email,
        name: name,
        defaultRole: role,
      }).toPromise();
  }

  getUserRoles(email: string) {
    return this.http
      .get(`${this.endPoint}/users/${email}/roles`).toPromise();
  }

  addUserRole(username: string, roleName: string) {
    const url = `${this.endPoint}/roles/${roleName}/users/${username}`;
    return this.http.put(url, {}).toPromise();
  }

  deleteUserRole(username: string, aclId: string) {
    const url = `${this.endPoint}/roles/${aclId}/users/${username}`;
    return this.http.delete(url).toPromise();
  }

  deleteUser(username: string) {
    const url = `${this.endPoint}/users/${username}`;
    return this.http.delete(url).toPromise();
  }

  updateUserDefaultRole(username: string, roleName: string) {
    return this.http
      .put(`${this.endPoint}/users/role/${roleName}`, {})
      .toPromise();
  }

  // roles
  getRoles(key = '') {
    return this.http
      .get(`${this.endPoint}/roles/search?key=${key}`).toPromise();
  }

  getRoleUsers(roleName: string) {
    return this.http
      .get(`${this.endPoint}/roles/${roleName}/users`).toPromise();
  }

  getRolesAllowedToCreate() {
    return this.http
      .get(`${this.endPoint}/roles/allowed`).toPromise();
  }

  getRoleScreens(roleName: string) {
    return this.http
      .get(`${this.endPoint}/roles/${roleName}/screens`).toPromise();
  }

  addRoleScreen(screenId: string, roleName: string) {
    return this.http
      .post(`${this.endPoint}/roles/${roleName}/screens/${screenId}`, {}).toPromise();
  }

  addRoleScreenWrite(screenId: string, roleName: string) {
    return this.http
      .post(`${this.endPoint}/roles/${roleName}/screens/${screenId}/write`, {}).toPromise();
  }

  deleteRoleScreen(screenId: string, roleName: string) {
    return this.http
      .delete(`${this.endPoint}/roles/${roleName}/screens/${screenId}`)
      .toPromise();
  }

  deleteRoleScreenWrite(screenId: string, roleName: string) {
    return this.http
      .delete(`${this.endPoint}/roles/${roleName}/screens/${screenId}/write`)
      .toPromise();
  }

  // screens
  getScreens(keyword = '') {
    return this.http
      .get(`${this.endPoint}/uis?search=${keyword}`).toPromise();
  }

  createScreen(screen: string) {
    return this.http
      .post(`${this.endPoint}/uis`, { screen: screen })
      .toPromise();
  }

  updateScreen(screenId: number, screen: string) {
    return this.http
      .put(`${this.endPoint}/uis/${screenId}`, { screen: screen })
      .pipe(catchError((error) => this.errorHandler(error)))
      .toPromise();
  }

  deleteScreen(screenId: string) {
    return this.http.delete(`${this.endPoint}/uis/${screenId}`).toPromise();
  }

  getScreenRoles(screenId: string) {
    return this.http
      .get(`${this.endPoint}/uis/${screenId}/roles`).toPromise();
  }

  getScreenLinkedApis(screenId: string) {
    return this.http
      .get(`${this.endPoint}/uis/${screenId}/apis`).toPromise();
  }

  addScreenApi(screenId: string, apiId: string) {
    return this.http
      .post(`${this.endPoint}/uis/${screenId}/apis/${apiId}`, {}).toPromise();
  }

  deleteScreenApi(screenId: string, apiId: string) {
    return this.http
      .delete(`${this.endPoint}/uis/${screenId}/apis/${apiId}`)
      .toPromise();
  }

  // apis
  getApis(key = '') {
    return this.http
      .get(`${this.endPoint}/apis/search?key=${key}`).toPromise();
  }
}
