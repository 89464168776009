import { Injectable, InjectionToken, Inject, Self } from '@angular/core';

export const STORAGE_KEY: InjectionToken<string> = new InjectionToken(
  'Token Storage Key',
  { providedIn: 'root', factory: () => 'oauthToken' }
);

export const LOGIN_URL: InjectionToken<string> = new InjectionToken(
  'Url Redirection to Login'
);

export const LOGIN_REDIRECT_KEY: InjectionToken<string> = new InjectionToken(
  'Url Search Parameter for Login Redirection',
  { providedIn: 'root', factory: () => 'redirect_uri' }
);

const ACCESS_TOKEN_REGEX = /[?&#]access_token=([^&]+)/;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    @Inject(STORAGE_KEY) @Self() protected storageKey: string,
    @Inject(LOGIN_URL) @Self() protected loginUrl: string,
    @Inject(LOGIN_REDIRECT_KEY) @Self() protected loginRedirectKey: string
  ) {
    if (!this.isAuthenticated()) {
      this.findAuthorizationToken();
    }
  }

  private redirect(url: string) {
    location.replace(url);
  }

  private findAuthorizationToken() {
    const matches = location.hash.match(ACCESS_TOKEN_REGEX);
    if (matches) {
      const [accessTokenHash, token] = matches;
      this.setAuthorizationToken(token);
      this.redirect(location.href.replace(accessTokenHash, ''));
    } else {
      this.login();
    }
  }

  private setAuthorizationToken(value: string) {
    localStorage.setItem(this.storageKey, value);
  }

  clearAuthorizationToken() {
    localStorage.removeItem(this.storageKey);
  }

  login() {
    this.clearAuthorizationToken();
    this.redirect(`${this.loginUrl}?${this.loginRedirectKey}=${location.href}`);
  }

  getAuthorizationToken() {
    return localStorage.getItem(this.storageKey);
  }

  isAuthenticated() {
    return !!this.getAuthorizationToken();
  }
}
