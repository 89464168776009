<modal #updateCTOSInfoModal [closeOnOutsideClick]="false" class="modal-update-ctos-info" >
  <modal-header>
    <h3>{{ 'member.ctos.modal.update_ctos_info.title' | translate }}</h3>
  </modal-header>
  <modal-content>
    <p class="message">
      {{ 'member.ctos.modal.update_ctos_info.message' | translate }}
    </p>
  </modal-content>
  <modal-footer>
    <button type="button" class="btn btn-primary btn-outline" (click)="close()">
      {{ 'button.cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-primary"
      (click)="onRetrieveCTOS()"
    >
      {{ 'member.ctos.modal.update_ctos_info.retrieve_ctos' | translate }}
    </button>
  </modal-footer>
</modal>
