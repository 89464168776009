import { Config, Type } from './config';

export const environment = {
  version: '{BUILD_VERSION}',
  production: false,
  name: 'staging',
  api: 'https://kong-internal-staging.fundingasiagroup.com/internal',
  base_website_url: 'https://web-staging.fsmkcommand.center',
  Base_api_url: 'https://kong-internal-staging.fundingasiagroup.com',
  Auth_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/auth',
  Parsing_service_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/dps',
  Bond_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/v1/esbn',
  // Deprecated: use External_Endpoint instead.
  CTOS_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/ext',
  // Deprecated: use External_Endpoint instead.
  Questnet_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/ext',
  External_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/ext',
  FA_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/v2/fa',
  Document_EndPoint_V3:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/docs_management/v3',
  Finops_Serverless_Workflows_Endpoint:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/fsw',
  DocGen_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/dg',
  Finance_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/fs',
  Finance_Endpoint_V2: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/fs/v2',
  Finance_Pairing_Endpoint:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/finance_pairing/v1',
  Member_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/ms',
  Member_EndPoint_V2: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/v2/members',
  SOA_EndPoint_V2: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/v2/soa-reports',
  Loan_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/ln',
  LookUpNodeJS_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/lu/p',
  Loyalty_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/v1/loyalty',
  SMS_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal_api/sms',
  Scheduler_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/sc',
  Logout_URI: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/logout?redirect=',
  Logout_Page_URL:
    'https://kong-internal-staging.fundingasiagroup.com/internal/login/logout.html?home=',
  Login_URI: 'https://kong-internal-staging.fundingasiagroup.com/internal/login/?redirect_uri=',
  User_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api',
  Notification_Endpoint:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/notification',
  Email_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/email',
  Staff_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/ss',
  Kyc_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/kyc/v1',
  CustomModule_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com:30051',
  MicroLoan_Endpoint: 'https://staging-microloan.fundingasiagroup.com',
  MicroLoan_Docs_Gen_Endpoint:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/docs_gen',
  MicroLoan_Internal_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/ml',
  Underwriting_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/credit',
  Credit_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/credit/v2',
  v2_url: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/v2',
  Partnership_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/partners',
  Collection_Endpoint:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/v1/loan-collections',
  Dispatch_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/dispatch',
  Facility_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/facility',
  Facility_Endpoint_V2:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/facility/v2',
  Investor_Channelling_Endpoint:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/ics',
  ELO_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/elo_rating',
  ELO_Rating_EndPoint:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/credit/v2/elo',
  Paylater_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/paylater',
  myTukar_account_Id: 754434,
  carlistbid_account_id: 754431,
  MUV_account_Id: 754433,
  carsome_account_Id: 1068468,
  launch_darkly_client_id: '5da5726df87c9308b76af5e5',
  Bolt_Partner_EndPoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/bpp',
  Borrower_Backend_Service_Endpoint:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/bbs',
  Ekyc_Esign_Service_Endpoint:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/ees',
  Bank_Integration_Service_Endpoint:
    'https://kong-internal-staging.fundingasiagroup.com/internal/api/bis',
  scfs: 'https://staging-scfs2.fundingasiagroup.com',
  bowIDOrigin: 'https://apply-staging.modalku.co.id',
  ezauto_account_Id: 1211312,
  hideNonMKItem: false, // For ID Audit
  hideNonFSItem: false, // For ID Audit
  Credit_Card_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/ccs',
  Business_Account_Endpoint: 'https://kong-internal-staging.fundingasiagroup.com/internal/api/bas',
  download_soa: `https://kong-internal-staging.fundingasiagroup.com/internal/api/dg/v1/document`,
  type: new Config(Type.STAGING),
  template_id: '4c700f50-33d1-4052-a5dc-a51173fd8538',
  sheet_name: 'Sheet1',
  qlmProductIds: [16, 13, 204],
  enablePrivy: true,
  productIds: {
    islamicMicrofinancingMY: 207,
    islamicEcommerceMY: 208,
    boltMyCgcd: 1788,
  },
  loanLevelSOA: {
    SG: {
      fs_capital: {
        template_id: '0ab3b9f9-6b01-4c89-9dc6-e1265d0f79d0',
      },
      fspl: {
        template_id: '9f3a9cba-7bae-41de-a555-30785811984f',
      },
    },
  },
  datadogConfiguration: {
    applicationId: '8f7172aa-f382-49c7-80ed-a31834318232',
    clientToken: 'pubb61ed877d78210599272412491422ae8',
  },
};
