<div
  class="form-group form-group--{{ style }} row"
  [ngClass]="{
    'has-error': hasError,
    required: required
  }"
>
  <label class="control-label" [ngClass]="labelGrid" *ngIf="label !== null">{{
    label
  }}</label>
  <div [ngClass]="controlGrid">
    <ng-content></ng-content>
  </div>
</div>
