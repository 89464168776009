import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import Config from '../../configuration/configuration';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    // Refer to configuration for available format
    const format = args && Config.date_format[args] ? args : 'date';
    return value ?
      super.transform(value, Config.date_format[format], '', Config.date_format.locale) : '';
  }
}
