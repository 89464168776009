import { Directive, ElementRef } from '@angular/core';
import { CdkCellDef, CdkCell, CdkColumnDef } from '@angular/cdk/table';

@Directive({
  selector: '[tableCell]',
  providers: [{ provide: CdkCellDef, useExisting: TableCellDef }],
})
export class TableCellDef extends CdkCellDef {}

@Directive({
  selector: 'table-cell',
})
export class TableCellDirective extends CdkCell {
  constructor(columnDef: CdkColumnDef, elementRef: ElementRef) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.add(
      `table-column-${columnDef.cssClassFriendlyName}`
    );
  }
}
