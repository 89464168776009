import { Injectable } from '@angular/core';
import { User } from '../models/user.class';
import { COUNTRY_CODES } from '../enum/facility-enum';
import { Observable, from } from 'rxjs';

import { StaffService } from './staff.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  constructor(private staffService: StaffService) {
  }

  getUser(): Observable<User> {
    return from(this.staffService.getCurrentUser());
  }
  /**
   * Save user profile into localStorage.
   */
  saveUserProfile(user: User) {
    const countryId = user.default_role.split('_')[1].toUpperCase();
    this.setCountryId(countryId);
    this.setCurrentRole(user.default_role);
    this.setUserName(user.username);
  }

  setCountryId(countryId: string): void {
    localStorage.setItem('countryId', countryId);
  }

  getModalkuCountryList(): Array<string> {
    return [COUNTRY_CODES.ID, COUNTRY_CODES.ZI];
  }

  getMYCountryList(): Array<string> {
    return [COUNTRY_CODES.MY];
  }

  getCountryId(): string {
    let result = localStorage.getItem('countryId');
    return typeof result !== 'undefined' && result !== '' && result !== null ? result : '';
  }

  setCurrentRole(role: string): void {
    localStorage.setItem('currentRole', role);
  }

  getCurrentRole(): string {
    let result = localStorage.getItem('currentRole');
    return typeof result !== 'undefined' && result !== '' && result !== null ? result : '';
  }

  setUserName(userName: string): void {
    localStorage.setItem('userName', userName);
  }

  getUserName(): string {
    let result = localStorage.getItem('userName');
    return typeof result !== 'undefined' && result !== '' && result !== null ? result : '';
  }
}


