export enum Type {
  STAGING = 'staging',
  UAT = 'uat',
  PROD = 'prod',
}

export class Config {

  readonly type: Type;

  constructor(type: Type) {
    this.type = type;
  }

  isStaging(): boolean {
    return this.type === Type.STAGING;
  }
  isUAT(): boolean {
    return this.type === Type.UAT;
  }
  isProd(): boolean {
    return this.type === Type.PROD;
  }
}
