<f-loading></f-loading>
<app-toast-messages></app-toast-messages>
<div id="wrapper">
    <app-navigation></app-navigation>
    <div id="page-wrapper" class="gray-bg">
        <div id="showEnvironment" class="alert alert-danger alert-dismissable text-center environmentBox"
            style="display:none">
            <a style="opacity:1" href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
            <div style="opacity:1" id="environmentName"></div>
        </div>
        <app-topnavbar></app-topnavbar>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div>

<div *ngIf="!authenticated">
    <div class="fs_loading"></div>
</div>