import { Route } from '@angular/router';

export const facilityRoute: Route = {
  path: 'facility',
  data: { title: 'Trade Finance' },
  loadChildren: () =>
    import('../../app/modules/facility_v2/facility_v2.module').then(
      (m) => m.FacilityV2Module
    ),
};
