import { Injectable } from '@angular/core';
import { createErrorLog, ErrorLog } from './error.model';
import { ErrorStore } from './error.store';

@Injectable({ providedIn: 'root' })
export class ErrorService {

  constructor(private errorStore: ErrorStore) {
  }

  add(error: ErrorLog) {
    this.errorStore.add(error);
  }

  addLog(error: Partial<ErrorLog>) {
    this.add(createErrorLog(error));
  }

  clear() {
    this.errorStore.remove();
  }
}
