import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class AlertMessage {
  public severity: string;
  public message: string;
  public duration: number;
}

@Injectable()
export class AlertService {
  public alertStatus: BehaviorSubject<AlertMessage> = new BehaviorSubject(<
    AlertMessage
  >{ severity: 'error', message: null });
  showAlertMultiple(severity: string, objMsg: string[]) {
    objMsg.forEach((message) => {
      this.showNormalAlert(severity, message);
    });
  }

  showNormalAlert(severity: string, msg: string, duration?: number) {
    let alertObj: AlertMessage = {
      severity: severity,
      message: msg,
      duration: duration,
    };
    this.alertStatus.next(alertObj);
  }

  showAlert(severity: string, obj: any) {
    if (Array.isArray(obj)) {
      this.showAlertMultiple(severity, obj);
    } else {
      this.showNormalAlert(severity, this.resolveError(obj));
    }
  }

  resolveError(obj: any): string {
    if (obj instanceof Error) {
      return obj.message;
    } else if (typeof obj === 'object') {
      return JSON.stringify(obj);
    }
    return obj;
  }
  severityToClass(severity) {
    severity = severity.toLowerCase();
    if (severity === 'error') {
      return 'alert alert-danger';
    } else if (severity === 'success') {
      return 'alert alert-success';
    } else if (severity === 'warn') {
      return 'alert alert-warning';
    } else {
      return 'alert alert-info';
    }
  }
  error(obj: any) {
    this.showAlert('error', obj);
  }
  success(obj: any) {
    this.showAlert('success', obj);
  }
  warn(obj: any) {
    this.showAlert('warn', obj);
  }
  info(obj: any) {
    this.showAlert('info', obj);
  }
}
