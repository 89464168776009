import { ProfileService } from '@shared-services/profile.service';
import { FeatureFlagService } from '@shared-services/feature-flag.service';
import { UtilityService } from '@shared-services/utility.service';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CONFIGURATION from '@shared-configuration/configuration';
import { MessageService } from '@shared-services/message.service';
import { environment } from '@shared-environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { Observable } from 'rxjs';
import { StaffService } from '@shared/services/staff.service';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
})
export class AppComponent {
  base_url: string;
  featureFlagObservable: Observable<any>;
  enableDataDog: boolean;
  maintenancePage = false;
  isIndonesiaHost = false;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private featureFlagService: FeatureFlagService,
    private profileService: ProfileService,
    private utilityService: UtilityService,
    private staffService: StaffService
  ) {
    this.translateService.setDefaultLang(
      CONFIGURATION && CONFIGURATION.language && CONFIGURATION.language.default
        ? CONFIGURATION.language.default
        : 'en'
    );
    this.isIndonesiaHost = this.utilityService.isIndonesiaHost();
  }

  ngOnInit() {
    this.base_url = window.location.origin;
    this.messageService.sendMessage('initializeLdClient');
    const { datadog, maintenanceMode } = this.featureFlagService.getFeatureFlagKeys();

    this.featureFlagObservable = this.featureFlagService.getFeatureFlagObservable();
    this.featureFlagObservable.subscribe((flags) => {
      if (this.isIndonesiaHost) {
        this.maintenancePage = flags && flags[maintenanceMode]['MK'];
      } else {
        this.maintenancePage = flags && flags[maintenanceMode]['FS'];
      }
      this.enableDataDog = flags[datadog];
      if (this.enableDataDog) {
        // avoid reinitializing datadog
        if (datadogRum.getInitConfiguration() === undefined) {
          this.initDataDog();
        }
      }
    });
    this.staffService.getCurrentUser().then((response) => {
      if (window['FS'])
        window['FS'].setUserVars({
          displayName: response.username,
        });
    });
  }

  private initDataDog() {
    datadogRum.init({
      applicationId: environment?.datadogConfiguration?.applicationId,
      clientToken: environment?.datadogConfiguration?.clientToken,
      site: 'datadoghq.com',
      service: 'command-center',
      env: environment.name,
      version: environment.version,
      sessionSampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      allowedTracingUrls: [environment?.Base_api_url].filter((x) => x), // remove null or undefined
    });
    datadogRum.setUser({
      email: this.profileService.getUserName(),
    });
  }
}
