import { Injectable } from '@angular/core';

@Injectable()
export class ReviewAppService {
  defaultEnvironment: string;
  localStorageEnvironmentKey: string;
  environments: Array<string>;
  constructor(
  ) {
    this.localStorageEnvironmentKey = 'environment';
    this.defaultEnvironment = 'uat';
    this.environments = [
      'staging',
      'uat',
      'uat-id'
    ];
  }

  changeEnvironment(environment: string) {
    if (environment) {
      localStorage.setItem(this.localStorageEnvironmentKey, environment);
    }
  }

  getEnvironment() {
    return localStorage.getItem(this.localStorageEnvironmentKey) || this.defaultEnvironment;
  }

  getEnvironments() {
    return this.environments;
  }
}
