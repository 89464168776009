import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { DateFormatPipe } from "./date-format.pipe";

@NgModule({
    imports : [
        CommonModule
    ],
    declarations:[
        DateFormatPipe
    ],
    exports: [
        DateFormatPipe
    ],
    providers:[
        DateFormatPipe,
        DatePipe
    ]
})

export class DateFormatPipeModule {}