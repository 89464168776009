<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
    <ul side-navigation class="nav" id="side-menu">
      <li class="nav-header">
        <div class="dropdown profile-element">
          <a class="dropdown-toggle" data-toggle="dropdown" href>
            <span class="clear">
              <div class="col-xs-3 padding-null">
                <img class="fs-logo" src="./member/assets/images/loading_fs.png" />
              </div>
              <div class="col-xs-9 padding-null mb-10">
                <span class="block" style="margin-bottom: 0.25em; font-size: 0.8em">
                  <em>Command Center - {{ environmentName }}</em>
                </span>
                <span class="block">
                  <strong class="font-bold">{{ institution }}</strong>
                </span>
              </div>
              <span class="text-muted text-xs block">{{ email }}</span>
              <span class="text-muted text-xs block">{{ defaultRole }}</span>
            </span>
          </a>
          <ul class="dropdown-menu animated fadeInRight m-t-xs">
            <li *ngFor="let userRole of userRoles" (click)="switchCountry(userRole)">
              <a>{{ userRole.label }}</a>
            </li>
          </ul>
        </div>
        <div class="logo-element">CC</div>
      </li>
      <ng-container *ngFor="let menu of menus">
        <li
          *ngIf="isAccessibleMenu(menu)"
          routerLinkActive="active"
          [ngClass]="{
            'hide-menu': menu.validateRoute && menu.children?.length && !isRouteExistInScreens(menu.validationRoutes)
          }"
        >
          <!-- When no child route -->
          <a *ngIf="!menu.children?.length" [routerLink]="menu.route" (click)="checkRoute(menu.route)" aria-expanded="false">
            <i *ngIf="!!menu.icon" [ngClass]="menu.icon"></i>
            <span class="nav-label">{{ menu.translateKey | translate }}</span>
          </a>
          <!-- When children routes are there -->
          <ng-container *ngIf="!!menu.children?.length">
            <a href="#" aria-expanded="false">
              <i *ngIf="!!menu.icon" [ngClass]="menu.icon"></i>
              <span class="nav-label">{{ menu.translateKey | translate }}</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level" routerLinkActive="open">
              <ng-container [ngTemplateOutlet]="navigationRepeatUI" [ngTemplateOutletContext]="{ menu: menu }"> </ng-container>
            </ul>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>

<ng-template #navigationRepeatUI let-menu="menu">
  <ng-container *ngFor="let child of menu.children">
    <li
      *ngIf="isAccessibleMenu(child)"
      routerLinkActive="active"
      [ngClass]="{
        'hide-menu': child.validateRoute && child.children?.length && !isRouteExistInScreens(child.validationRoutes)
      }"
    >
      <!-- When no child route -->
      <a
        *ngIf="!child.children?.length && !child.external"
        [routerLink]="child.route"
        (click)="checkRoute(child.route)"
        aria-expanded="false"
      >
        <span class="nav-label">{{ child.translateKey | translate }}</span>
      </a>
      <a *ngIf="!child.children?.length && child.external" [href]="child.route" target="_blank" aria-expanded="false">
        <span class="nav-label">{{ child.translateKey | translate }}</span>
      </a>
      <!-- When children routes are there -->
      <a *ngIf="!!child.children?.length" href="#" aria-expanded="false">
        <i *ngIf="!!child.icon" [ngClass]="menu.icon"></i>
        <span class="nav-label">{{ child.translateKey | translate }}</span>
        <span class="fa arrow"></span>
      </a>
      <ul class="nav nav-second-level" routerLinkActive="open">
        <ng-container [ngTemplateOutlet]="navigationRepeatUI" [ngTemplateOutletContext]="{ menu: child }"></ng-container>
      </ul>
    </li>
  </ng-container>
</ng-template>
