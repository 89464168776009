<modal
  class="confirm-modal"
  #confirmationModal
  [closeOnOutsideClick]="false"
  [hideCloseButton]="true">
  <modal-content>
    <h3>{{ confirmation.title }}</h3>
    <p>{{ confirmation.message }}</p>
  </modal-content>
  <modal-footer>
    <button
      class="btn btn-primary btn-outline"
      data-testid="cancel"
      (click)="confirmation.onNo()">
      Cancel
    </button>
    <button
      class="btn btn-primary"
      data-testid="continue"
      (click)="confirmation.onYes()">
      Continue
    </button>
  </modal-footer>
</modal>
